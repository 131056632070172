import { createPushModal } from 'pushmodal'
import RefundBookingOptionsModal from '@/components/modals/RefundBookingOptionsModal.tsx'
import CustomerRefundBookingOptionsModal from '@/components/modals/CustomerRefundBookingOptionsModal.tsx'
import { UpdateMeetingTimeModal } from '@/components/modals/UpdateMeetingTimeModal.tsx'
import DeleteMeetingConfirmationModal from '@/components/modals/DeleteMeetingConfirmationModal.tsx'
import { CreateBookingLinkModal } from '@/components/modals/CreateBookingLinkModal.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import { UpdateOrganizationalBookingModal } from '@/components/modals/UpdateOrganizationalBookingModal.tsx'
import { TransferBookingModal } from '@/components/modals/TransferBookingModal.tsx'

export const { pushModal, popModal, popAllModals, ModalProvider } = createPushModal({
  modals: {
    RefundBookingOptionsModal: {
      Component: RefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    TransferBookingModal: {
      Component: TransferBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
    CustomerRefundBookingOptionsModal: {
      Component: CustomerRefundBookingOptionsModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateMeetingTimeModal: {
      Component: UpdateMeetingTimeModal,
      Wrapper: Dynamic.Wrapper,
    },
    DeleteMeetingConfirmationModal: {
      Component: DeleteMeetingConfirmationModal,
      Wrapper: Dynamic.Wrapper,
    },
    CreateBookingLinkModal: {
      Component: CreateBookingLinkModal,
      Wrapper: Dynamic.Wrapper,
    },
    UpdateOrganizationalBookingModal: {
      Component: UpdateOrganizationalBookingModal,
      Wrapper: Dynamic.Wrapper,
    },
  },
})
