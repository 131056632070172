/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingEntity } from '../models/BookingEntity';
import type { MultipassEntity } from '../models/MultipassEntity';
import type { MultipassProductEntity } from '../models/MultipassProductEntity';
import type { MultipassProductWithCourseIdsEntityDto } from '../models/MultipassProductWithCourseIdsEntityDto';
import type { PostMultipassDto } from '../models/PostMultipassDto';
import type { PostMultipassProductDto } from '../models/PostMultipassProductDto';
import type { UpdateMultipassProductDto } from '../models/UpdateMultipassProductDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MultipassService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a new Multipass Product
     * @param requestBody
     * @returns MultipassProductEntity The Multipass Product has been created
     * @throws ApiError
     */
    public multipassControllerCreateMultipassProduct(
        requestBody: PostMultipassProductDto,
    ): CancelablePromise<MultipassProductEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/multipass/product',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all Multipass Products
     * @returns MultipassProductWithCourseIdsEntityDto Multipass Products have been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipassProducts(): CancelablePromise<Array<MultipassProductWithCourseIdsEntityDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass/product',
        });
    }
    /**
     * Get a single Multipass Product by its id
     * @param multipassProductId
     * @returns MultipassProductWithCourseIdsEntityDto Multipass Product has been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipassProduct(
        multipassProductId: string,
    ): CancelablePromise<MultipassProductWithCourseIdsEntityDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass/product/{multipassProductId}',
            path: {
                'multipassProductId': multipassProductId,
            },
        });
    }
    /**
     * Update a Multipass Product
     * @param multipassProductId
     * @param requestBody
     * @returns MultipassProductEntity The updated Multipass Product has been returned
     * @throws ApiError
     */
    public multipassControllerUpdateMultipassProduct(
        multipassProductId: string,
        requestBody: UpdateMultipassProductDto,
    ): CancelablePromise<MultipassProductEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/multipass/product/{multipassProductId}',
            path: {
                'multipassProductId': multipassProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all Multipasses of a Product
     * @param multipassProductId
     * @returns MultipassEntity The Multipass have been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipassesByProduct(
        multipassProductId: string,
    ): CancelablePromise<Array<MultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass/product/{multipassProductId}/multipasses',
            path: {
                'multipassProductId': multipassProductId,
            },
        });
    }
    /**
     * Create a new Multipass (issue product to a Guest or Customer)
     * @param multipassProductId
     * @param requestBody
     * @returns MultipassEntity The Multipass has been issued
     * @throws ApiError
     */
    public multipassControllerIssueMultipass(
        multipassProductId: string,
        requestBody: PostMultipassDto,
    ): CancelablePromise<MultipassEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/multipass/product/{multipassProductId}/issue',
            path: {
                'multipassProductId': multipassProductId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all Multipasses
     * @returns MultipassEntity Multipasses have been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipasses(): CancelablePromise<Array<MultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass',
        });
    }
    /**
     * Get a Multipass
     * @param multipassId
     * @returns MultipassEntity Multipasses has been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipass(
        multipassId: string,
    ): CancelablePromise<MultipassEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass/{multipassId}',
            path: {
                'multipassId': multipassId,
            },
        });
    }
    /**
     * Get Bookings that a Multipass has been used for
     * @param multipassId
     * @returns BookingEntity Bookings have been returned
     * @throws ApiError
     */
    public multipassControllerGetMultipassBookings(
        multipassId: string,
    ): CancelablePromise<BookingEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/multipass/{multipassId}/bookings',
            path: {
                'multipassId': multipassId,
            },
        });
    }
}
