/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FormQuestionSubmissionEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    bookingStatus: FormQuestionSubmissionEntity.bookingStatus;
    organizationId: string | null;
    instructorId: string | null;
    requiredBy: string | null;
    name: string;
    archived: boolean;
    description: string;
    finished: boolean;
    formId: string;
    customerId: string | null;
    guestId: string | null;
    formSubmissionId: string;
    formQuestionId: string;
    submittedSelectRadioOption: string;
    submittedSelectCheckboxOptions: Array<any[]>;
    submittedDate: string;
    submittedText: string;
};
export namespace FormQuestionSubmissionEntity {
    export enum bookingStatus {
        DRAFT = 'DRAFT',
        RESERVED = 'RESERVED',
        CLAIMED = 'CLAIMED',
        FULFILLED = 'FULFILLED',
        REFUNDED = 'REFUNDED',
        CANCELLED = 'CANCELLED',
        CANCELLED_REFUNDED = 'CANCELLED_REFUNDED',
        WITHDRAWN = 'WITHDRAWN',
    }
}

