import { ComponentPropsWithoutRef, useEffect, useState } from 'react'

type HighlightProps = ComponentPropsWithoutRef<'div'> & {
  trigger: unknown
  duration: number
  highlightCallback?: (highlighting: boolean) => void
}

export default function Highlight({
  trigger,
  duration,
  children,
  ...props
}: HighlightProps) {
  const [previous, setPrevious] = useState(trigger)
  const [didHighlight, setDidHighlight] = useState(false)

  useEffect(() => {
    if (props.highlightCallback && previous !== trigger) {
      props.highlightCallback(true)
    }

    const handler = setTimeout(() => {
      if (previous !== trigger) {
        setDidHighlight(true)
        if (props.highlightCallback) {
          props.highlightCallback(false)
        }
      }
      setPrevious(trigger)
    }, duration)

    return () => {
      clearTimeout(handler)
    }
  }, [duration, previous, trigger])

  return (
    <div
      data-highlight={previous !== trigger ? 'on' : didHighlight ? 'off' : null}
      {...props}
    >
      {children}
    </div>
  )
}
