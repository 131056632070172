import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { BookingEntity } from '../../../qourses-api-client'

export default function useGetBooking(bookingId: string): {
  booking: BookingEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: booking,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(getBookingQueryKey(bookingId), async () => {
    return qoursesApi.payment.paymentControllerGetBooking(bookingId)
  })

  return { booking, isLoading, isError, refetch, isRefetching }
}

export const getBookingQueryKey = (bookingId: string) => ['booking', bookingId]
