import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import {PublicBookingWithPriceDetailsEntity} from "../../../qourses-api-client";

export default function useGetOrderBookingsPublic(orderId: string): {
  bookings: PublicBookingWithPriceDetailsEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: bookings,
    isLoading,
    isError,
    refetch,
  } = useQuery(['public', 'order', orderId, 'bookings'], async () => {
    return qoursesApi.paymentPublic.paymentPublicControllerGetOrderBookings(orderId)
  })

  let isEmpty = true

  if (bookings === undefined) {
    return { bookings: [], isLoading, isEmpty, isError, refetch }
  }

  if (bookings.length > 0) {
    isEmpty = false
  }

  return { bookings, isLoading, isEmpty, isError, refetch }
}

export const GetOrderBookingsPublicQueryKey = (orderId: string) => [
  'public',
  'order',
  orderId,
  'bookings',
]
