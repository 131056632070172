import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { useTranslation } from 'react-i18next'
import useGetBookingRefundOptions from '@/hooks/orders/useGetBookingRefundOptions.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { Checkbox } from '@/shadcn/components/ui/checkbox.tsx'
import { useEffect, useState } from 'react'
import { ToggleGroup, ToggleGroupItem } from '@/shadcn/components/ui/toggle-group.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import {
  BookingEntity,
  PostCancellationDto,
  RefundOptionDto,
} from '../../../qourses-api-client'
import { qoursesApi } from '@/api/qourses.tsx'
import { minDelay, sendNotification, ToastVariant } from '@/utils.tsx'
import { Loader2 } from 'lucide-react'
import { popAllModals } from '@/components/modals/index.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { GetOrderBookingsQueryKey } from '@/hooks/orders/useGetOrderBookings.tsx'
import { GetOrderQueryKey } from '@/hooks/orders/useGetOrder.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'
import useGetBooking, { getBookingQueryKey } from '@/hooks/orders/useGetBooking.tsx'
import { GetOrderBookingsWithLineItemQueryKey } from '@/hooks/orders/useGetOrderBookingsWithLineItem.tsx'
import { GetBookingsQueryKey } from '@/hooks/orders/useGetBookings.tsx'

export default function RefundBookingOptionsModal({ bookingId }: { bookingId: string }) {
  const { t: translate } = useTranslation()

  const queryClient = useQueryClient()

  const [overrideTOS, setOverrideTOS] = useState(false)
  const [cancelBooking, setCancelBooking] = useState(true)
  const [selectedRefundOption, setSelectedRefundOption] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const { bookingCancellationOptions, isLoading, isError, refetch, isRefetching } =
    useGetBookingRefundOptions(bookingId, overrideTOS)

  const {
    booking,
    isLoading: isLoadingBooking,
    isError: isErrorBooking,
  } = useGetBooking(bookingId)

  useEffect(() => {
    refetch()
  }, [overrideTOS])

  if (isError || isErrorBooking) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            {translate('modals.refundBookingModal.errorState.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.refundBookingModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (isLoadingBooking || isLoading) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            <Skeleton className="h-5 w-3/4" />
          </DialogTitle>
          <DialogDescription className="space-y-2">
            <Skeleton className="h-20 w-full" />
            <Skeleton className="h-20 w-full" />
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  if (
    booking.bookingStatus === BookingEntity.bookingStatus.CANCELLED &&
    bookingCancellationOptions.refundNotNecessary === true
  ) {
    return (
      <Dynamic.Content className="min-h-[30%] p-4 sm:p-8">
        <DialogHeader className="mb-2">
          <DialogTitle className="mt-4">
            {translate('modals.refundBookingModal.cancellationNotNecessary.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.refundBookingModal.cancellationNotNecessary.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  const refundOptionStringArray = bookingCancellationOptions?.refundOptions.map(
    (refundOption) => refundOption.type.toString(),
  )

  const availableRefundOptions = [
    RefundOptionDto.type.REPAYMENT.toString(),
    RefundOptionDto.type.MULTIPASS.toString(),
  ]

  const notAvailableRefundOptions = availableRefundOptions.filter(
    (refundOption) => !refundOptionStringArray?.includes(refundOption),
  )

  async function handleRefund() {
    try {
      setSubmitting(true)
      // Determine if we also want to remove the user from the booking or not while refunding
      if (cancelBooking) {
        await minDelay(
          qoursesApi.payment.paymentControllerCancelBookingAndRefundIfEligible(
            bookingId,
            overrideTOS,
            {
              refundOptionType:
                //TODO: Leverage NONE instead of null once the BE supports this more broadly
                selectedRefundOption !== PostCancellationDto.refundOptionType.NONE
                  ? (selectedRefundOption as PostCancellationDto.refundOptionType)
                  : null,
            },
          ),
          800,
        )
      } else {
        // We only want to refund the booking, without cancelling the customer out of it
        await minDelay(
          qoursesApi.payment.paymentControllerRefundBooking(bookingId, {
            refundOptionType:
              selectedRefundOption !== PostCancellationDto.refundOptionType.NONE
                ? (selectedRefundOption as PostCancellationDto.refundOptionType)
                : null,
          }),
          800,
        )
      }
      sendNotification(
        translate('modals.refundBookingModal.notification.title'),
        translate('modals.refundBookingModal.notification.subtitle'),
        ToastVariant.Success,
      )
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
      popAllModals()
      queryClient.invalidateQueries(GetOrderBookingsQueryKey(booking.orderId))
      queryClient.invalidateQueries(GetOrderBookingsWithLineItemQueryKey(booking.orderId))
      queryClient.invalidateQueries(GetOrderQueryKey(booking.orderId))
      queryClient.invalidateQueries(getBookingQueryKey(bookingId))
      queryClient.invalidateQueries(GetBookingsQueryKey())
    }
  }

  const overrideTOSDisabled =
    bookingCancellationOptions && bookingCancellationOptions.refundNotNecessary === true

  const cancelBookingDisabled =
    isLoadingBooking ||
    booking.bookingStatus === 'CANCELLED' ||
    (bookingCancellationOptions && bookingCancellationOptions.refundNotNecessary === true)

  const cancelBookingChecked =
    booking &&
    booking.bookingStatus !== 'CANCELLED' &&
    ((bookingCancellationOptions &&
      bookingCancellationOptions.refundNotNecessary === true) ||
      cancelBooking)

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {translate('modals.refundBookingModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.refundBookingModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      {!overrideTOSDisabled && (
        <div className="mt-2 flex justify-start gap-x-2 text-xs">
          <Checkbox
            id="overrideTOS"
            checked={overrideTOS}
            onClick={() => {
              setSelectedRefundOption(null)
              setOverrideTOS(!overrideTOS)
            }}
          />
          <label htmlFor="overrideTOS" className="text-sm font-medium leading-none">
            {translate('modals.refundBookingModal.overrideTOS.title')}
            <p className="mt-1 text-xs text-gray-600">
              {translate('modals.refundBookingModal.overrideTOS.subtitle')}
            </p>
          </label>
        </div>
      )}
      {!cancelBookingDisabled && (
        <div className="mb-4 mt-2 flex justify-start gap-x-2 text-xs">
          <Checkbox
            id="cancelBooking"
            checked={cancelBookingChecked}
            onClick={() => {
              setSelectedRefundOption(null)
              setCancelBooking(!cancelBooking)
            }}
            className="disabled:opacity-60"
          />
          <label htmlFor="cancelBooking" className="text-sm font-medium leading-none">
            {translate('modals.refundBookingModal.cancelBooking.title')}
            <p className="mt-1 text-xs text-gray-600">
              {translate('modals.refundBookingModal.cancelBooking.subtitle')}
            </p>
          </label>
        </div>
      )}
      {isRefetching ? (
        <div className="space-y-2">
          <Skeleton className="h-5 w-3/4" />
          <Skeleton className="h-5 w-1/2" />
          <Skeleton className="h-5 w-3/5" />
        </div>
      ) : (
        <>
          <div className="text-md mb-2 mt-4 font-semibold text-foreground">
            {translate('modals.refundBookingModal.refundOptions.title')}
            <p className="text-sm font-normal text-muted-foreground">
              {translate('modals.refundBookingModal.refundOptions.subtitle')}
            </p>
          </div>
          <ToggleGroup
            type="single"
            onValueChange={(value) => {
              setSelectedRefundOption(value)
            }}
            value={selectedRefundOption}
            className="grid grid-cols-1 gap-3"
          >
            {cancelBooking && (
              <div>
                <ToggleGroupItem
                  value={RefundOptionDto.type.NONE}
                  disabled={!cancelBooking}
                  id={RefundOptionDto.type.NONE}
                  className="peer sr-only"
                >
                  {RefundOptionDto.type.NONE}
                </ToggleGroupItem>
                <Label
                  htmlFor={RefundOptionDto.type.NONE}
                  className="flex cursor-pointer flex-col items-start justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=on]:border-primary sm:p-4 [&:has([data-state=on])]:border-primary"
                >
                  {translate('modals.refundBookingModal.refundOptions.NONE.title')}
                  <p className="mt-1 font-normal text-gray-500">
                    {translate('modals.refundBookingModal.refundOptions.NONE.subtitle')}
                  </p>
                </Label>
              </div>
            )}
            {bookingCancellationOptions.refundNotNecessary === false &&
              bookingCancellationOptions.refundOptions.map((refundOption) => (
                <div>
                  <ToggleGroupItem
                    value={refundOption.type}
                    id={refundOption.type}
                    className="peer sr-only"
                  >
                    {refundOption.type}
                  </ToggleGroupItem>
                  <Label
                    htmlFor={refundOption.type}
                    className="flex cursor-pointer flex-col items-start justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=on]:border-primary sm:p-4 [&:has([data-state=on])]:border-primary"
                  >
                    {translate(
                      'modals.refundBookingModal.refundOptions.' +
                        refundOption.type +
                        '.title',
                    )}
                    <p className="mt-1 font-normal text-gray-500">
                      {translate(
                        'modals.refundBookingModal.refundOptions.' +
                          refundOption.type +
                          '.subtitle',
                      )}
                    </p>
                  </Label>
                </div>
              ))}
            {bookingCancellationOptions.refundNotNecessary === false &&
              notAvailableRefundOptions.map((refundOption) => (
                <div>
                  <ToggleGroupItem
                    value={refundOption}
                    disabled={true}
                    id={refundOption}
                    className="peer sr-only"
                  >
                    {refundOption}
                  </ToggleGroupItem>
                  <Label
                    htmlFor={refundOption}
                    className="flex cursor-pointer flex-col items-start justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-accent hover:text-accent-foreground peer-data-[state=on]:border-primary sm:p-4 [&:has([data-state=on])]:border-primary"
                  >
                    {translate(
                      'modals.refundBookingModal.refundOptions.' +
                        refundOption +
                        '.title',
                    )}
                    <p className="mt-1 font-normal text-gray-500">
                      {translate(
                        'modals.refundBookingModal.refundOptions.' +
                          refundOption +
                          '.subtitle',
                      )}
                    </p>
                  </Label>
                </div>
              ))}
          </ToggleGroup>
          {bookingCancellationOptions.refundNotNecessary === true && (
            <div className="text-sm text-gray-500">
              {translate('modals.refundBookingModal.refundNotNecessary')}
            </div>
          )}
          <Button
            variant="indigo"
            disabled={
              submitting ||
              (cancelBooking && !selectedRefundOption) ||
              (!cancelBooking && !selectedRefundOption)
            }
            onClick={handleRefund}
            className="mt-2"
          >
            {submitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {translate('common.loading')}
              </>
            ) : (
              translate('modals.refundBookingModal.refundButton')
            )}
          </Button>
        </>
      )}
      <DialogFooter></DialogFooter>
    </Dynamic.Content>
  )
}
