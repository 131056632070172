/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BookingCancellationRefundEligibilityDto } from '../models/BookingCancellationRefundEligibilityDto';
import type { CustomerMultipassEntity } from '../models/CustomerMultipassEntity';
import type { OrderEntity } from '../models/OrderEntity';
import type { PatchBookingDto } from '../models/PatchBookingDto';
import type { PostCancellationDto } from '../models/PostCancellationDto';
import type { PublicBookingEntity } from '../models/PublicBookingEntity';
import type { PublicBookingWithPriceDetailsEntity } from '../models/PublicBookingWithPriceDetailsEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get orders
     * @returns OrderEntity The Orders have been returned
     * @throws ApiError
     */
    public paymentCustomerControllerGetOrders(): CancelablePromise<Array<OrderEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/payment/order',
        });
    }
    /**
     * Get an Order
     * @param orderId
     * @returns OrderEntity The Order has been returned
     * @throws ApiError
     */
    public paymentCustomerControllerGetOrder(
        orderId: string,
    ): CancelablePromise<OrderEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/payment/order/{orderId}',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Get the Bookings of an Order
     * @param orderId
     * @returns PublicBookingWithPriceDetailsEntity The Bookings of the Order have been returned
     * @throws ApiError
     */
    public paymentCustomerControllerGetOrderBookings(
        orderId: string,
    ): CancelablePromise<Array<PublicBookingWithPriceDetailsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/payment/order/{orderId}/bookings',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Change Booking details
     * @param bookingId
     * @param requestBody
     * @returns PublicBookingEntity The Booking details have been changed
     * @throws ApiError
     */
    public paymentCustomerControllerPatchBooking(
        bookingId: string,
        requestBody: PatchBookingDto,
    ): CancelablePromise<PublicBookingEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/customer/payment/booking/{bookingId}',
            path: {
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Get useable Multipasses that can be applied to the bookings of the Order
     * @param orderId
     * @returns CustomerMultipassEntity The Multipasses have been returned
     * @throws ApiError
     */
    public paymentCustomerControllerGetUseableMultipassesForOrder(
        orderId: string,
    ): CancelablePromise<Array<CustomerMultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/payment/order/{orderId}/useableMultipasses',
            path: {
                'orderId': orderId,
            },
            errors: {
                404: `The Order has not been found`,
            },
        });
    }
    /**
     * Get cancellation refund eligibility of a Booking
     * @param bookingId
     * @returns BookingCancellationRefundEligibilityDto The cancellation refund eligibility has been returned
     * @throws ApiError
     */
    public paymentCustomerControllerGetBookingCancellationRefundEligibility(
        bookingId: string,
    ): CancelablePromise<BookingCancellationRefundEligibilityDto> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/payment/booking/{bookingId}/cancel',
            path: {
                'bookingId': bookingId,
            },
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
    /**
     * Cancel a Booking and trigger a refund if eligible
     * @param bookingId
     * @param requestBody
     * @returns PublicBookingEntity The Booking has cancelled and returned
     * @throws ApiError
     */
    public paymentCustomerControllerCancelBookingAndRefundIfEligible(
        bookingId: string,
        requestBody: PostCancellationDto,
    ): CancelablePromise<PublicBookingEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/payment/booking/{bookingId}/cancel',
            path: {
                'bookingId': bookingId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `The Booking has not been found`,
            },
        });
    }
}
