/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PostScheduleDto } from '../models/PostScheduleDto';
import type { ScheduleEntity } from '../models/ScheduleEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SchedulingService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a schedule
     * @param requestBody
     * @returns ScheduleEntity Schedule has been created
     * @throws ApiError
     */
    public schedulingControllerCreateSchedule(
        requestBody: PostScheduleDto,
    ): CancelablePromise<ScheduleEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/scheduling',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Schedule has not been found`,
            },
        });
    }
    /**
     * Retrieve next-occurrences for given schedule
     * @param scheduleId
     * @returns string Array of next occurrences in ISO 8601 format
     * @throws ApiError
     */
    public schedulingControllerGetNextOccurrences(
        scheduleId: string,
    ): CancelablePromise<Array<string>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/scheduling/{scheduleId}/next-occurrences',
            path: {
                'scheduleId': scheduleId,
            },
            errors: {
                404: `Schedule has not been found`,
            },
        });
    }
}
