import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { TagEntity } from '../../../qourses-api-client'
import useGetTags from '@/hooks/tags/useGetTags.tsx'

export function TagSelection(props: {
  selectedTag: TagEntity
  setSelectedTag: Dispatch<SetStateAction<TagEntity>>
  disabled: boolean
}) {
  const { t: translate } = useTranslation()
  const { tags, isLoading, isError } = useGetTags()

  const [tagJson, setTagJson] = useState<string | null>(null)

  useEffect(() => {
    // just rerender if the prop changes
  }, [props.selectedTag])

  useEffect(() => {
    if (tagJson) {
      const course = JSON.parse(tagJson).value as TagEntity

      props.setSelectedTag(course)
    }
  }, [tagJson])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.courses.placeholder')}
          ></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedTags = tags.map((tag) => ({
    value: tag,
    label: tag.name,
  }))

  return (
    <>
      <Select
        onValueChange={(value) => setTagJson(value)}
        value={tagJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.tags.placeholder')}
          ></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.tags.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedTags.map((tag) => (
                <SelectItem value={JSON.stringify(tag)}>{tag.label}</SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
