/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateInstructorDto = {
    /**
     * The first name of the instructor
     */
    firstName?: string;
    /**
     * The last name of the instructor
     */
    lastName?: string;
    /**
     * The bio of the instructor
     */
    bio?: string;
    /**
     * The pronouns of the instructor
     */
    pronouns?: UpdateInstructorDto.pronouns;
};
export namespace UpdateInstructorDto {
    /**
     * The pronouns of the instructor
     */
    export enum pronouns {
        HE_HIM = 'HE_HIM',
        SHE_HER = 'SHE_HER',
        THEY_THEM = 'THEY_THEM',
        NONE = 'NONE',
    }
}

