import { motion } from 'framer-motion'
import { useTranslation } from 'react-i18next'
import { useRouteError } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { useEffect } from 'react'

export default function GlobalErrorPage() {
  const { t: translate } = useTranslation()

  const error = useRouteError()

  useEffect(() => {
    if (error) {
      console.error('Caught Error via Global Error Page', error)
      Sentry.captureException(error)
    }
  }, [])

  if (
    error instanceof Error &&
    (error.message.includes('Failed to fetch dynamically imported module') ||
      error.message.includes('error loading dynamically imported module') ||
      error.message.includes('SyntaxError: Unexpected token'))
  ) {
    window.location.reload()
    return null
  }

  let statusURL = import.meta.env.VITE_API_URL
  // cut 'api' from the url and replace with 'status'
  if (statusURL.includes('api')) {
    statusURL = statusURL.replace('api', 'status')
  } else {
    statusURL = 'https://status.qourses.space'
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 30 }}
      animate={{ opacity: 1, y: 0 }}
      className="grid min-h-full place-items-center bg-white px-6 py-24 sm:py-32 lg:px-8"
    >
      <div className="flex flex-col items-center text-center">
        <p className="text-3xl font-semibold text-indigo-600">🔥🔥🔥🚿🧑‍🚒 {''} 🚒🚒</p>
        <h1 className="mt-4 text-xl font-bold tracking-tight text-gray-900 sm:text-3xl">
          {translate('pages.error.title')}
        </h1>
        <div className="mt-3 rounded-lg bg-gray-100 p-2 text-xs">{error.toString()}</div>
        <p className="mt-2 max-w-lg text-base leading-7 text-gray-600">
          {translate('pages.error.subtitle')}
        </p>
        <div className="mt-5 flex items-center justify-center gap-x-6">
          <iframe
            src={statusURL + '/badge?theme=light'}
            className="items-center rounded-lg bg-gray-50 ring-1 ring-gray-200"
            width="182"
            height="30"
            scrolling="no"
            frameBorder="0"
          />
        </div>
      </div>
    </motion.div>
  )
}
