/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PostCancellationDto = {
    refundOptionType: PostCancellationDto.refundOptionType | null;
};
export namespace PostCancellationDto {
    export enum refundOptionType {
        NONE = 'NONE',
        REPAYMENT = 'REPAYMENT',
        CREDITS = 'CREDITS',
        MULTIPASS = 'MULTIPASS',
    }
}

