import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { CourseGroupEntity } from '../../../qourses-api-client'
import isEmpty from 'lodash/isEmpty'

export default function useGetCourseGroupsOfCourse(courseId: string): {
  courseGroups: CourseGroupEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: courseGroups,
    isLoading,
    refetch,
    isError,
  } = useQuery(['courses', 'courseGroups', courseId], async () => {
    return qoursesApi.course.courseControllerGetCourseGroups(courseId)
  })

  const _isEmpty = isEmpty(courseGroups)

  if (courseGroups === undefined) {
    return { courseGroups: [], isLoading, isEmpty: _isEmpty, isError, refetch }
  }

  return { courseGroups, isLoading, isEmpty: _isEmpty, isError, refetch }
}
