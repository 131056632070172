/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicInstructorEntity } from '../models/PublicInstructorEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstructorPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get an Instructor
     * @param instructorId
     * @returns PublicInstructorEntity Instructor has been returned
     * @throws ApiError
     */
    public instructorPublicControllerGetInstructor(
        instructorId: string,
    ): CancelablePromise<PublicInstructorEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/instructor/{instructorId}',
            path: {
                'instructorId': instructorId,
            },
            errors: {
                404: `Instructor has not been found`,
            },
        });
    }
}
