/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type InstructorEntity = {
    id: string;
    organizationId: string;
    createdAt: string;
    updatedAt: string;
    pronouns: InstructorEntity.pronouns | null;
    firstName: string;
    lastName: string;
    bio: string | null;
};
export namespace InstructorEntity {
    export enum pronouns {
        HE_HIM = 'HE_HIM',
        SHE_HER = 'SHE_HER',
        THEY_THEM = 'THEY_THEM',
        NONE = 'NONE',
    }
}

