/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormQuestionEntity } from '../models/FormQuestionEntity';
import type { FormQuestionSubmissionEntity } from '../models/FormQuestionSubmissionEntity';
import type { PostFormQuestionSubmissionDto } from '../models/PostFormQuestionSubmissionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SurveyPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Form Questions of Form Submission
     * @param formSubmissionId
     * @returns FormQuestionEntity Form Questions have been returned
     * @throws ApiError
     */
    public surveyPublicControllerGetFormQuestionsOfFormSubmission(
        formSubmissionId: string,
    ): CancelablePromise<Array<FormQuestionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/survey/formSubmission/{formSubmissionId}/formQuestions',
            path: {
                'formSubmissionId': formSubmissionId,
            },
        });
    }
    /**
     * Create Form Question Submissions for Form Question in Form Submission
     * @param formSubmissionId
     * @param requestBody
     * @returns FormQuestionSubmissionEntity Form Question Submission has been returned
     * @throws ApiError
     */
    public surveyPublicControllerCreateFormQuestionSubmissionsForFormSubmission(
        formSubmissionId: string,
        requestBody: PostFormQuestionSubmissionDto,
    ): CancelablePromise<FormQuestionSubmissionEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/survey/formSubmission/{formSubmissionId}/formQuestionSubmission',
            path: {
                'formSubmissionId': formSubmissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Form Submission has not been found`,
            },
        });
    }
}
