/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvitationEntity } from '../models/InvitationEntity';
import type { InvitationWithOrganizationEntity } from '../models/InvitationWithOrganizationEntity';
import type { InvitationWithSecretEntity } from '../models/InvitationWithSecretEntity';
import type { PostInvitationDto } from '../models/PostInvitationDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InvitationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Invitations
     * @returns InvitationEntity Invitations have been returned
     * @throws ApiError
     */
    public invitationControllerGetAllInvitations(): CancelablePromise<Array<InvitationEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/invitation',
        });
    }
    /**
     * Create a new invitation
     * @param requestBody
     * @returns InvitationWithSecretEntity The created invitation including the secret
     * @throws ApiError
     */
    public invitationControllerCreateInvitation(
        requestBody: PostInvitationDto,
    ): CancelablePromise<InvitationWithSecretEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/invitation',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Retrieve an invitation via its secret
     * @param invitationSecret
     * @returns InvitationWithOrganizationEntity The retrieved invitation
     * @throws ApiError
     */
    public invitationControllerGetInvitationFromSecret(
        invitationSecret: string,
    ): CancelablePromise<InvitationWithOrganizationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/invitation/secret/{invitationSecret}',
            path: {
                'invitationSecret': invitationSecret,
            },
        });
    }
    /**
     * Accept an invitation
     * @param invitationSecret
     * @returns InvitationEntity The retrieved invitation
     * @throws ApiError
     */
    public invitationControllerAcceptInvitation(
        invitationSecret: string,
    ): CancelablePromise<InvitationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/invitation/secret/{invitationSecret}',
            path: {
                'invitationSecret': invitationSecret,
            },
        });
    }
    /**
     * Delete an invitation
     * @param invitationId
     * @returns InvitationEntity The deleted invitation
     * @throws ApiError
     */
    public invitationControllerDeleteInvitation(
        invitationId: string,
    ): CancelablePromise<InvitationEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/invitation/{invitationId}',
            path: {
                'invitationId': invitationId,
            },
        });
    }
}
