/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type LegalTermsEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    organizationId: string | null;
    instructorId: string | null;
    type: LegalTermsEntity.type;
    title: string;
    text: string;
    archived: boolean;
    replacesId: string | null;
    replacedById: string | null;
};
export namespace LegalTermsEntity {
    export enum type {
        PRIVACY_POLICY = 'PRIVACY_POLICY',
        TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
        REVOCATION_POLICY = 'REVOCATION_POLICY',
    }
}

