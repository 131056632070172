/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type BookingEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    bookingStatus: BookingEntity.bookingStatus;
    organizationId: string;
    orderId: string | null;
    meetingId: string;
    /**
     * The date and time until the booking doesn't block a spot in the meeting anymore.
     */
    exclusiveUntil: string | null;
    cancelled: boolean;
    guestId: string | null;
    customerId: string | null;
    multipassId: string | null;
    courseGroupId: string | null;
    multipassProductId: string | null;
    priceInMillsOverride: number | null;
    replaced: boolean;
    withdrawn: boolean;
};
export namespace BookingEntity {
    export enum bookingStatus {
        DRAFT = 'DRAFT',
        RESERVED = 'RESERVED',
        CLAIMED = 'CLAIMED',
        FULFILLED = 'FULFILLED',
        REFUNDED = 'REFUNDED',
        CANCELLED = 'CANCELLED',
        CANCELLED_REFUNDED = 'CANCELLED_REFUNDED',
        WITHDRAWN = 'WITHDRAWN',
    }
}

