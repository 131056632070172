/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicMeetingEntity } from '../models/PublicMeetingEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MeetingPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a Meeting
     * @param meetingId
     * @returns PublicMeetingEntity Meeting has been returned
     * @throws ApiError
     */
    public meetingPublicControllerGetMeeting(
        meetingId: string,
    ): CancelablePromise<PublicMeetingEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/meeting/{meetingId}',
            path: {
                'meetingId': meetingId,
            },
            errors: {
                404: `Meeting has not been found`,
            },
        });
    }
}
