/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicCourseEntity } from '../models/PublicCourseEntity';
import type { PublicMultipassProductEntity } from '../models/PublicMultipassProductEntity';
import type { PublicMultipassProductWithBookingInfoEntity } from '../models/PublicMultipassProductWithBookingInfoEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MultipassPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Multipass Products
     * @param organizationId
     * @returns PublicMultipassProductWithBookingInfoEntity The Multipass Products have been returned
     * @throws ApiError
     */
    public multipassPublicControllerGetMultipassProducts(
        organizationId: string,
    ): CancelablePromise<Array<PublicMultipassProductWithBookingInfoEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/multipass/product',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get a Multipass Product
     * @param multipassProductId
     * @returns PublicMultipassProductEntity The Multipass Product has been returned
     * @throws ApiError
     */
    public multipassPublicControllerGetMultipassProduct(
        multipassProductId: string,
    ): CancelablePromise<PublicMultipassProductEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/multipass/product/{multipassProductId}',
            path: {
                'multipassProductId': multipassProductId,
            },
        });
    }
    /**
     * Get the Courses a Multipass Product is valid for
     * @param multipassProductId
     * @returns PublicCourseEntity The Courses have been returned
     * @throws ApiError
     */
    public multipassPublicControllerGetMultipassProductEligibleCourses(
        multipassProductId: string,
    ): CancelablePromise<Array<PublicCourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/multipass/product/{multipassProductId}/courses',
            path: {
                'multipassProductId': multipassProductId,
            },
        });
    }
}
