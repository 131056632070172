import {
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { useTranslation } from 'react-i18next'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { useEffect, useState } from 'react'
import { ToggleGroup, ToggleGroupItem } from '@/shadcn/components/ui/toggle-group.tsx'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { PostCancellationDto, RefundOptionDto } from '../../../qourses-api-client'
import { qoursesApi } from '@/api/qourses.tsx'
import { minDelay } from '@/utils.tsx'
import { CalendarOff, Coins, Loader2, Ticket, Undo2 } from 'lucide-react'
import useGetCustomerBookingRefundOptions from '@/hooks/public/useGetCustomerBookingRefundOptions.tsx'
import { Alert, AlertDescription, AlertTitle } from '@/shadcn/components/ui/alert.tsx'
import { DateTime } from 'luxon'
import { GetOrderBookingsPublicQueryKey } from '@/hooks/public/useGetOrderBookingsPublic.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { GetEndCustomerMultipassesQueryKey } from '@/hooks/customer/useGetEndCustomerMultipasses.tsx'
import { popAllModals } from '@/components/modals/index.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'

export default function CustomerRefundBookingOptionsModal({
  bookingId,
  orderId,
}: {
  bookingId: string
  orderId: string
}) {
  const { t: translate } = useTranslation()

  const [selectedRefundOption, setSelectedRefundOption] = useState(undefined)
  const [submitting, setSubmitting] = useState(false)

  const { bookingCancellationOptions, isLoading, isError } =
    useGetCustomerBookingRefundOptions(bookingId)

  const queryClient = useQueryClient()

  // If done loading, setSelectedRefundOption the first available refund option
  useEffect(() => {
    if (
      !isLoading &&
      bookingCancellationOptions?.refundOptions &&
      bookingCancellationOptions.refundOptions[0]
    ) {
      setSelectedRefundOption(bookingCancellationOptions.refundOptions[0].type)
    }
  }, [isLoading])

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            {translate('modals.customerRefundBookingModal.errorState.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.customerRefundBookingModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  async function handleRefund() {
    try {
      const refundOption = selectedRefundOption as PostCancellationDto.refundOptionType
      setSubmitting(true)
      await minDelay(
        qoursesApi.paymentCustomer.paymentCustomerControllerCancelBookingAndRefundIfEligible(
          bookingId,
          {
            refundOptionType: refundOption ?? undefined,
          },
        ),
        800,
      )
    } catch (e) {
      console.error(e)
    } finally {
      setSubmitting(false)
      popAllModals()
      queryClient.invalidateQueries(GetOrderBookingsPublicQueryKey(orderId))
      // Also refetch multipasses to avoid showing wrong multipass count if user navigates back
      queryClient.invalidateQueries(GetEndCustomerMultipassesQueryKey())
    }
  }

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {translate('modals.customerRefundBookingModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.customerRefundBookingModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      {isLoading ? (
        <div className="space-y-2">
          <Skeleton className="h-5 w-3/4" />
          <Skeleton className="h-5 w-1/2" />
          <Skeleton className="h-5 w-3/5" />
        </div>
      ) : (
        <>
          {!bookingCancellationOptions.refundable && (
            <Alert className="my-4 sm:my-0">
              <CalendarOff className="h-4 w-4" />
              <AlertTitle>
                {translate('modals.customerRefundBookingModal.notRefundableNoticeTitle')}
              </AlertTitle>
              <AlertDescription>
                {translate(
                  'modals.customerRefundBookingModal.notRefundableNoticeSubtitle',
                )}
              </AlertDescription>
            </Alert>
          )}
          {bookingCancellationOptions.refundable && (
            <>
              <div className="text-md font-semibold text-foreground">
                {translate('modals.customerRefundBookingModal.refundOptions.title')}
                <p className="text-sm font-normal text-muted-foreground">
                  {translate('modals.customerRefundBookingModal.refundOptions.subtitle', {
                    date: DateTime.fromISO(
                      bookingCancellationOptions.refundableUntil,
                    ).toLocaleString(DateTime.DATETIME_MED),
                  })}
                </p>
              </div>
              <ToggleGroup
                type="single"
                onValueChange={(value) => {
                  setSelectedRefundOption(value)
                }}
                value={selectedRefundOption}
                className={
                  'sm:grid-rows- grid grid-cols-1' +
                  bookingCancellationOptions.refundOptions.length
                }
              >
                {bookingCancellationOptions.refundOptions.map((refundOption) => (
                  <div>
                    <ToggleGroupItem
                      value={refundOption.type}
                      id={refundOption.type}
                      className="peer sr-only"
                    >
                      {refundOption.type}
                    </ToggleGroupItem>
                    <Label
                      htmlFor={refundOption.type}
                      className="flex cursor-pointer flex-col items-start justify-between rounded-md border-2 border-muted bg-popover p-4 hover:bg-accent hover:text-accent-foreground peer-data-[state=on]:border-primary [&:has([data-state=on])]:border-primary"
                    >
                      <div className="flex items-center gap-x-2">
                        {refundOption.type === RefundOptionDto.type.MULTIPASS && (
                          <Ticket className="size-4" />
                        )}
                        {refundOption.type === RefundOptionDto.type.CREDITS && (
                          <Coins className="size-4" />
                        )}
                        {refundOption.type === RefundOptionDto.type.REPAYMENT && (
                          <Undo2 className="size-4" />
                        )}
                        {translate(
                          'modals.customerRefundBookingModal.refundOptions.' +
                            refundOption.type +
                            '.title',
                        )}
                      </div>
                      <p className="mt-1.5 text-gray-500">
                        {translate(
                          'modals.customerRefundBookingModal.refundOptions.' +
                            refundOption.type +
                            '.subtitle',
                        )}
                      </p>
                    </Label>
                  </div>
                ))}
              </ToggleGroup>
            </>
          )}
          <Button
            variant="indigo"
            disabled={
              submitting ||
              (!selectedRefundOption && bookingCancellationOptions.refundable)
            }
            onClick={handleRefund}
          >
            {submitting ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {translate('common.loading')}
              </>
            ) : (
              translate('modals.customerRefundBookingModal.refundButton')
            )}
          </Button>
        </>
      )}
      <DialogFooter></DialogFooter>
    </Dynamic.Content>
  )
}
