/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicLegalTermsEntity } from '../models/PublicLegalTermsEntity';
import type { PublicTermsOfServiceEntity } from '../models/PublicTermsOfServiceEntity';
import type { RevocationPolicyEntity } from '../models/RevocationPolicyEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LegalPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Legal Terms
     * @param legalTermsId
     * @returns PublicLegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalPublicControllerGetLegalTermsById(
        legalTermsId: string,
    ): CancelablePromise<PublicLegalTermsEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/legal/terms/{legalTermsId}',
            path: {
                'legalTermsId': legalTermsId,
            },
        });
    }
    /**
     * Get Terms of Service
     * @param termsOfServiceId
     * @returns PublicTermsOfServiceEntity Terms of Service has been returned
     * @throws ApiError
     */
    public legalPublicControllerGetTermsOfServiceById(
        termsOfServiceId: string,
    ): CancelablePromise<PublicTermsOfServiceEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/legal/termsOfService/{termsOfServiceId}',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
        });
    }
    /**
     * Get Legal Terms of Terms of Service
     * @param termsOfServiceId
     * @returns PublicLegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalPublicControllerGetLegalTermsOfTermsOfServiceById(
        termsOfServiceId: string,
    ): CancelablePromise<Array<PublicLegalTermsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/legal/termsOfService/{termsOfServiceId}/legalTerms',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
        });
    }
    /**
     * Get Revocation Policy
     * @param revocationPolicyId
     * @returns RevocationPolicyEntity Revocation Policy has been returned
     * @throws ApiError
     */
    public legalPublicControllerGetRevocationPolicyById(
        revocationPolicyId: string,
    ): CancelablePromise<RevocationPolicyEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/legal/revocationPolicy/{revocationPolicyId}',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
        });
    }
    /**
     * Get Legal Terms of Revocation Policy
     * @param revocationPolicyId
     * @returns PublicLegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalPublicControllerGetLegalTermsOfRevocationPolicyById(
        revocationPolicyId: string,
    ): CancelablePromise<Array<PublicLegalTermsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/legal/revocationPolicy/{revocationPolicyId}/legalTerms',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
        });
    }
}
