import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { TagEntity } from '../../../qourses-api-client'

export default function useGetTags(): {
  tags: TagEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: tags,
    isLoading,
    isError,
    refetch,
  } = useQuery(['tags'], async () => {
    return qoursesApi.tag.tagControllerGetAllTags('false')
  })

  let isEmpty = true

  if (tags === undefined) {
    return { tags: [], isLoading, isEmpty, isError, refetch }
  }

  if (tags.length > 0) {
    isEmpty = false
  }

  return { tags, isLoading, isEmpty, isError, refetch }
}
