/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerMultipassEntity } from '../models/CustomerMultipassEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CourseCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Multipasses for course
     * @param courseId
     * @returns CustomerMultipassEntity Multipasses have been returned
     * @throws ApiError
     */
    public courseCustomerControllerGetCourseMultipasses(
        courseId: string,
    ): CancelablePromise<Array<CustomerMultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/course/{courseId}/multipasses',
            path: {
                'courseId': courseId,
            },
        });
    }
}
