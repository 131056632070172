/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { FormEntity } from '../models/FormEntity';
import type { InstructorEntity } from '../models/InstructorEntity';
import type { LocationEntity } from '../models/LocationEntity';
import type { MeetingEntity } from '../models/MeetingEntity';
import type { PostCourseDto } from '../models/PostCourseDto';
import type { ScheduleEntity } from '../models/ScheduleEntity';
import type { TagEntity } from '../models/TagEntity';
import type { UpdateCourseDto } from '../models/UpdateCourseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CourseService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Courses
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public courseControllerGetCourses(): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course',
        });
    }
    /**
     * Create a Course
     * @param requestBody
     * @returns CourseEntity Course has been created
     * @throws ApiError
     */
    public courseControllerCreateCourse(
        requestBody: PostCourseDto,
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/course',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a Course
     * @param courseId
     * @returns CourseEntity Course has been returned
     * @throws ApiError
     */
    public courseControllerGetCourse(
        courseId: string,
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}',
            path: {
                'courseId': courseId,
            },
        });
    }
    /**
     * Delete a Course
     * @param courseId
     * @returns CourseEntity Course has been deleted
     * @throws ApiError
     */
    public courseControllerDeleteCourse(
        courseId: string,
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/course/{courseId}',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Update a Course
     * @param courseId
     * @param requestBody
     * @returns CourseEntity The updated course
     * @throws ApiError
     */
    public courseControllerUpdateCourse(
        courseId: string,
        requestBody: UpdateCourseDto,
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/course/{courseId}',
            path: {
                'courseId': courseId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get all Course Groups of a Course
     * @param courseId
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseGroups(
        courseId: string,
    ): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/courseGroups',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get all ungrouped Meetings of a Course
     * @param courseId
     * @returns MeetingEntity Course Meetings have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseMeetings(
        courseId: string,
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/meetings',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get all instructors of a course
     * @param courseId
     * @returns InstructorEntity Instructors have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseInstructors(
        courseId: string,
    ): CancelablePromise<Array<InstructorEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/instructors',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get all schedules of a course
     * @param courseId
     * @returns ScheduleEntity Schedules have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseSchedules(
        courseId: string,
    ): CancelablePromise<Array<ScheduleEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/schedule',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get the location of a course
     * @param courseId
     * @returns LocationEntity Location has been returned
     * @throws ApiError
     */
    public courseControllerGetCourseLocation(
        courseId: string,
    ): CancelablePromise<LocationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/location',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get the forms of a course
     * @param courseId
     * @returns FormEntity Forms have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseForms(
        courseId: string,
    ): CancelablePromise<Array<FormEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/forms',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get the tags of a course
     * @param courseId
     * @returns TagEntity Tags have been returned
     * @throws ApiError
     */
    public courseControllerGetCourseTags(
        courseId: string,
    ): CancelablePromise<Array<TagEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/course/{courseId}/tags',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Tags has not been found`,
            },
        });
    }
}
