/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerEntity } from '../models/CustomerEntity';
import type { GuestEntity } from '../models/GuestEntity';
import type { MultipassEntity } from '../models/MultipassEntity';
import type { OrderEntity } from '../models/OrderEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Customers
     * @returns CustomerEntity The Customers have been returned
     * @throws ApiError
     */
    public customerControllerGetCustomers(): CancelablePromise<Array<CustomerEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer',
        });
    }
    /**
     * Get a Customer
     * @param customerId
     * @returns CustomerEntity The Customer has been returned
     * @throws ApiError
     */
    public customerControllerGetCustomer(
        customerId: string,
    ): CancelablePromise<CustomerEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/{customerId}',
            path: {
                'customerId': customerId,
            },
            errors: {
                404: `The Customer has not been found`,
            },
        });
    }
    /**
     * Get Customer Orders
     * @param customerId
     * @returns OrderEntity The Customer Orders have been returned
     * @throws ApiError
     */
    public customerControllerGetCustomerOrders(
        customerId: string,
    ): CancelablePromise<Array<OrderEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/{customerId}/orders',
            path: {
                'customerId': customerId,
            },
            errors: {
                404: `The Customer has not been found`,
            },
        });
    }
    /**
     * Get Customer Multipasses
     * @param customerId
     * @returns MultipassEntity The Customer Multipasses have been returned
     * @throws ApiError
     */
    public customerControllerGetCustomerMultipasses(
        customerId: string,
    ): CancelablePromise<Array<MultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/{customerId}/multipasses',
            path: {
                'customerId': customerId,
            },
            errors: {
                404: `The Customer has not been found`,
            },
        });
    }
    /**
     * Get Guests
     * @returns GuestEntity The Guests have been returned
     * @throws ApiError
     */
    public customerControllerGetGuests(): CancelablePromise<Array<GuestEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/guest',
        });
    }
    /**
     * Get Guest
     * @param guestId
     * @returns GuestEntity The Guest has been returned
     * @throws ApiError
     */
    public customerControllerGetGuest(
        guestId: string,
    ): CancelablePromise<GuestEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/guest/{guestId}',
            path: {
                'guestId': guestId,
            },
            errors: {
                404: `The Guest has not been found`,
            },
        });
    }
    /**
     * Get Guest Orders
     * @param guestId
     * @returns OrderEntity The Guest Orders have been returned
     * @throws ApiError
     */
    public customerControllerGetGuestOrders(
        guestId: string,
    ): CancelablePromise<Array<OrderEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/guest/{guestId}/orders',
            path: {
                'guestId': guestId,
            },
            errors: {
                404: `The Guest has not been found`,
            },
        });
    }
    /**
     * Get Guest Multipasses
     * @param guestId
     * @returns MultipassEntity The Guest Multipasses have been returned
     * @throws ApiError
     */
    public customerControllerGetGuestMultipasses(
        guestId: string,
    ): CancelablePromise<Array<MultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/guest/{guestId}/multipasses',
            path: {
                'guestId': guestId,
            },
            errors: {
                404: `The Guest has not been found`,
            },
        });
    }
}
