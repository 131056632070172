/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type UpdateOrganizationUserNotificationPreferencesDto = {
    bookingNotifications?: UpdateOrganizationUserNotificationPreferencesDto.bookingNotifications;
    orderNotifications?: UpdateOrganizationUserNotificationPreferencesDto.orderNotifications;
    waitingListNotifications?: UpdateOrganizationUserNotificationPreferencesDto.waitingListNotifications;
    userAddedToOrganizationNotifications?: boolean;
    organizationRenamedNotifications?: boolean;
};
export namespace UpdateOrganizationUserNotificationPreferencesDto {
    export enum bookingNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
    export enum orderNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
    export enum waitingListNotifications {
        ORGANIZATION = 'ORGANIZATION',
        INSTRUCTOR = 'INSTRUCTOR',
        NONE = 'NONE',
    }
}

