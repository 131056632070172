/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { LocationEntity } from '../models/LocationEntity';
import type { PostLocationDto } from '../models/PostLocationDto';
import type { UpdateLocationDto } from '../models/UpdateLocationDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LocationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Locations
     * @returns LocationEntity Locations have been returned
     * @throws ApiError
     */
    public locationControllerGetLocations(): CancelablePromise<Array<LocationEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/location',
            errors: {
                404: `Location has not been found`,
            },
        });
    }
    /**
     * Create a Location
     * @param requestBody
     * @returns LocationEntity Location has been created
     * @throws ApiError
     */
    public locationControllerCreateLocation(
        requestBody: PostLocationDto,
    ): CancelablePromise<LocationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/location',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get a Location
     * @param locationId
     * @returns LocationEntity Location has been returned
     * @throws ApiError
     */
    public locationControllerGetLocation(
        locationId: string,
    ): CancelablePromise<LocationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/location/{locationId}',
            path: {
                'locationId': locationId,
            },
            errors: {
                404: `Location has not been found`,
            },
        });
    }
    /**
     * Update a Location
     * @param locationId
     * @param requestBody
     * @returns LocationEntity Location has been updated
     * @throws ApiError
     */
    public locationControllerUpdateLocation(
        locationId: string,
        requestBody: UpdateLocationDto,
    ): CancelablePromise<LocationEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/location/{locationId}',
            path: {
                'locationId': locationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Delete a Location
     * @param locationId
     * @returns LocationEntity Location has been deleted
     * @throws ApiError
     */
    public locationControllerDeleteLocation(
        locationId: string,
    ): CancelablePromise<LocationEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/location/{locationId}',
            path: {
                'locationId': locationId,
            },
            errors: {
                404: `Location has not been found`,
            },
        });
    }
}
