/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicOrganizationEntity } from '../models/PublicOrganizationEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get an Organization
     * @param organizationId
     * @returns PublicOrganizationEntity Organization has been returned
     * @throws ApiError
     */
    public organizationPublicControllerGetPublicOrganization(
        organizationId: string,
    ): CancelablePromise<PublicOrganizationEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/organization/{organizationId}',
            path: {
                'organizationId': organizationId,
            },
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
}
