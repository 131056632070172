/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FormQuestionSubmissionEntity } from '../models/FormQuestionSubmissionEntity';
import type { FormSubmissionEntity } from '../models/FormSubmissionEntity';
import type { PatchReplaceFormSubmissionDto } from '../models/PatchReplaceFormSubmissionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class SurveyCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all formQuestionSubmissions of a FormSubmission
     * @param formSubmissionId
     * @returns FormQuestionSubmissionEntity Form Question Submissions have been returned
     * @throws ApiError
     */
    public surveyCustomerControllerGetFormQuestionSubmissionsOfFormSubmission(
        formSubmissionId: string,
    ): CancelablePromise<Array<FormQuestionSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/survey/formSubmission/{formSubmissionId}/formQuestionSubmissions',
            path: {
                'formSubmissionId': formSubmissionId,
            },
            errors: {
                404: `Form Submission has not been found`,
            },
        });
    }
    /**
     * Get already finished Form Submissions of Form
     * @param formId
     * @returns FormSubmissionEntity Form Submissions have been returned
     * @throws ApiError
     */
    public surveyCustomerControllerGetFinishedFormSubmissionsOfForm(
        formId: string,
    ): CancelablePromise<Array<FormSubmissionEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/survey/form/{formId}/finishedFormSubmissions',
            path: {
                'formId': formId,
            },
        });
    }
    /**
     * Replace Form Submission with already existing finished Form Submission
     * @param formSubmissionId
     * @param requestBody
     * @returns FormSubmissionEntity Form Submission has been replaced and returned
     * @throws ApiError
     */
    public surveyCustomerControllerReplaceFormSubmissionWithFinishedFormSubmission(
        formSubmissionId: string,
        requestBody: PatchReplaceFormSubmissionDto,
    ): CancelablePromise<FormSubmissionEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/customer/survey/formSubmission/{formSubmissionId}/replaceWithFinishedFormSubmission',
            path: {
                'formSubmissionId': formSubmissionId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Replacing Form Submission has not been finished`,
                404: `Form Submission has not been found`,
            },
        });
    }
}
