import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import useGetCourseMeetings from '@/hooks/courses/useGetCourseMeetings.tsx'
import { DateTime } from 'luxon'
import useGetCourseGroupsOfCourse from '@/hooks/courses/useGetCourseGroupsOfCourse.tsx'
import { BookableType } from '@/pages/public/booking/components/CourseMeetings.tsx'
import { CourseGroupEntity, MeetingEntity } from '../../../qourses-api-client'

export type IdentifiableBookableBackoffice = {
  bookable: MeetingEntity | CourseGroupEntity | null
  bookableType: BookableType.Meeting | BookableType.CourseGroup
}

// Type guard for PublicMeetingWithBookingInfoEntity
export function isMeeting(
  bookable: any,
  bookableType: BookableType,
): bookable is MeetingEntity {
  return bookableType === BookableType.Meeting && bookable !== null
}

// Type guard for CourseGroupEntity
export function isCourseGroup(
  bookable: any,
  bookableType: BookableType,
): bookable is CourseGroupEntity {
  return bookableType === BookableType.CourseGroup && bookable !== null
}

export function CourseBookableSelection(props: {
  courseId: string
  selectedBookable: IdentifiableBookableBackoffice
  setSelectedBookable: Dispatch<SetStateAction<IdentifiableBookableBackoffice>>
  disabled: boolean
}) {
  const { t: translate } = useTranslation()
  const { meetings, isLoading, isError } = useGetCourseMeetings(props.courseId)
  const {
    courseGroups,
    isLoading: isLoadingGroups,
    isError: isErrorCourseGroups,
  } = useGetCourseGroupsOfCourse(props.courseId)

  const [meetingJson, setMeetingJson] = useState<string | null>(null)

  useEffect(() => {
    // just rerender if the prop changes
  }, [props.setSelectedBookable])

  useEffect(() => {
    if (meetingJson) {
      const meeting = JSON.parse(meetingJson).value as IdentifiableBookableBackoffice

      if (isMeeting(meeting.bookable, meeting.bookableType)) {
        props.setSelectedBookable({
          bookable: meeting.bookable,
          bookableType: BookableType.Meeting,
        })
      }

      if (isCourseGroup(meeting.bookable, meeting.bookableType)) {
        props.setSelectedBookable({
          bookable: meeting.bookable,
          bookableType: BookableType.CourseGroup,
        })
      }
    }
  }, [meetingJson])

  if (isLoading || isError || isLoadingGroups || isErrorCourseGroups) {
    return null
  }

  const mappedMeetings = meetings.map((meeting) => ({
    value: {
      bookable: meeting,
      bookableType: 'meeting',
    },
    label: DateTime.fromISO(meeting.start).toLocaleString(DateTime.DATETIME_MED),
  }))

  const mappedCourseGroups = courseGroups.map((courseGroup) => ({
    value: {
      bookable: courseGroup,
      bookableType: 'courseGroup',
    },
    label: courseGroup.name,
  }))

  if (isLoading || isLoadingGroups) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.courseMeetings.placeholder')}
          ></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const meetingsAndGroupsEmpty =
    mappedMeetings.length === 0 && mappedCourseGroups.length === 0

  return (
    <>
      <Select
        onValueChange={(value) => setMeetingJson(value)}
        value={meetingJson ?? undefined}
        disabled={props.disabled || meetingsAndGroupsEmpty}
      >
        <SelectTrigger className="bg-white">
          {meetingsAndGroupsEmpty ? (
            <SelectValue placeholder={translate('dropdowns.courseMeetings.empty')} />
          ) : (
            <SelectValue
              placeholder={translate('dropdowns.courseMeetings.placeholder')}
            />
          )}
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.courseMeetings.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading && !meetingsAndGroupsEmpty && (
              <>
                {mappedCourseGroups && mappedCourseGroups.length > 0
                  ? mappedCourseGroups.map((meeting) => (
                      <SelectItem value={JSON.stringify(meeting)}>
                        {meeting.label}
                      </SelectItem>
                    ))
                  : mappedMeetings.map((meeting) => (
                      <SelectItem value={JSON.stringify(meeting)}>
                        {meeting.label}
                      </SelectItem>
                    ))}
              </>
            )}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
