/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CustomerMultipassEntity } from '../models/CustomerMultipassEntity';
import type { PublicCourseEntity } from '../models/PublicCourseEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MultipassCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get Multipasses
     * @returns CustomerMultipassEntity The Multipasses have been returned
     * @throws ApiError
     */
    public multipassCustomerControllerGetMultipasses(): CancelablePromise<Array<CustomerMultipassEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/multipass',
        });
    }
    /**
     * Get a Multipass
     * @param multipassId
     * @returns CustomerMultipassEntity The Multipass has been returned
     * @throws ApiError
     */
    public multipassCustomerControllerGetMultipass(
        multipassId: string,
    ): CancelablePromise<CustomerMultipassEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/multipass/{multipassId}',
            path: {
                'multipassId': multipassId,
            },
        });
    }
    /**
     * Get the Courses a Multipass is valid for
     * @param multipassId
     * @returns PublicCourseEntity The Courses have been returned
     * @throws ApiError
     */
    public multipassCustomerControllerGetMultipassEligibleCourses(
        multipassId: string,
    ): CancelablePromise<Array<PublicCourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/customer/multipass/{multipassId}/courses',
            path: {
                'multipassId': multipassId,
            },
        });
    }
}
