import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { BookingEntity } from '../../../qourses-api-client'

export default function useGetBookings(): {
  bookings: BookingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: bookings,
    isLoading,
    isError,
  } = useQuery(['bookings'], async () => {
    return qoursesApi.payment.paymentControllerGetBookings()
  })

  let isEmpty = true

  if (bookings === undefined) {
    return { bookings: [], isLoading, isEmpty, isError }
  }

  if (bookings.length > 0) {
    isEmpty = false
  }

  return { bookings, isLoading, isEmpty, isError }
}

export const GetBookingsQueryKey = () => ['bookings']
