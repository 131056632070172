/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FormEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    bookingStatus: FormEntity.bookingStatus;
    organizationId: string | null;
    instructorId: string | null;
    name: string;
    description: string;
    archived: boolean;
};
export namespace FormEntity {
    export enum bookingStatus {
        DRAFT = 'DRAFT',
        RESERVED = 'RESERVED',
        CLAIMED = 'CLAIMED',
        FULFILLED = 'FULFILLED',
        REFUNDED = 'REFUNDED',
        CANCELLED = 'CANCELLED',
        CANCELLED_REFUNDED = 'CANCELLED_REFUNDED',
        WITHDRAWN = 'WITHDRAWN',
    }
}

