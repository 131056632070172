/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { PostTagDto } from '../models/PostTagDto';
import type { TagEntity } from '../models/TagEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TagService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create Tag
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TagEntity Tag has been returned
     * @throws ApiError
     */
    public tagControllerCreateTag(
        requestBody: PostTagDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TagEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/tag',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all Tags
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TagEntity Tags have been returned
     * @throws ApiError
     */
    public tagControllerGetAllTags(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<TagEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tag',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get a Tag
     * @param tagId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TagEntity Tag has been returned
     * @throws ApiError
     */
    public tagControllerGetTag(
        tagId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TagEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tag/{tagId}',
            path: {
                'tagId': tagId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Tag has not been found`,
            },
        });
    }
    /**
     * Get Courses of Tag
     * @param tagId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public tagControllerGetTagCourses(
        tagId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tag/{tagId}/courses',
            path: {
                'tagId': tagId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Course Groups of Tag
     * @param tagId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public tagControllerGetTagCourseGroups(
        tagId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/tag/{tagId}/courseGroups',
            path: {
                'tagId': tagId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
}
