import { useTranslation } from 'react-i18next'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import { Skeleton } from '@/shadcn/components/ui/skeleton.tsx'
import { MultipassProductEntity } from '../../../qourses-api-client'
import useGetMultipassProducts from '@/hooks/multipasses/useGetMultipassProducts.tsx'

export function MultipassProductSelection(props: {
  selectedMultipassProduct: MultipassProductEntity
  setSelectedMultipassProduct: Dispatch<SetStateAction<MultipassProductEntity>>
  disabled: boolean
}) {
  const { t: translate } = useTranslation()
  const { multipassProducts, isLoading, isError } = useGetMultipassProducts()

  const [MultipassProductJson, setMultipassProductJson] = useState<string | null>(null)

  useEffect(() => {
    // just rerender if the prop changes
  }, [props.setSelectedMultipassProduct])

  useEffect(() => {
    if (MultipassProductJson) {
      const MultipassProduct = JSON.parse(MultipassProductJson)
        .value as MultipassProductEntity

      props.setSelectedMultipassProduct(MultipassProduct)
    }
  }, [MultipassProductJson])

  if (isLoading || isError) {
    return (
      <Select disabled={true}>
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.multipassProducts.placeholder')}
          ></SelectValue>
        </SelectTrigger>
      </Select>
    )
  }

  const mappedMultipassProducts = multipassProducts.map((product) => ({
    value: product,
    label: product.name,
  }))

  return (
    <>
      <Select
        onValueChange={(value) => setMultipassProductJson(value)}
        value={MultipassProductJson ?? undefined}
        disabled={props.disabled ?? false}
      >
        <SelectTrigger className="bg-white">
          <SelectValue
            placeholder={translate('dropdowns.multipassProducts.placeholder')}
          ></SelectValue>
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{translate('dropdowns.multipassProducts.title')}</SelectLabel>
            {isLoading && (
              <div className="space-y-2">
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
                <Skeleton className="h-[25px] bg-gray-200" />
              </div>
            )}
            {!isLoading &&
              mappedMultipassProducts.map((product) => (
                <SelectItem value={JSON.stringify(product)}>{product.label}</SelectItem>
              ))}
          </SelectGroup>
        </SelectContent>
      </Select>
    </>
  )
}
