export const organizationIdParam = 'o'
export const courseIdParam = 'c'
export const meetingIdParam = 'm'
export const multipassProductIdParam = 'mp'
export const advertisingIdParam = 'a'
export const orderIdParam = 'orderId'
export const emailParam = 'email'
export const tagIdParam = 'tagId'
export const customerIdParam = 'customerId'
export const demoParam = 'demo'
export const iframeParam = 'iframe'
export const currentOrderMeetings = 'qourses-current-order-meetings'
export const orderLocalStorageKey = 'qourses-current-order'
