import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { FormQuestionEntity } from '../../../qourses-api-client'

export default function useGetFormQuestions(formId: string): {
  formQuestions: FormQuestionEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => Promise<any>
  isFetching: boolean
} {
  const {
    data: formQuestions,
    isLoading,
    isError,
    refetch,
    isFetching,
  } = useQuery(getFormQuestionsQueryKey(formId), async () => {
    return qoursesApi.survey.surveyControllerGetFormQuestions(formId, 'false')
  })

  let isEmpty = true

  if (formQuestions === undefined) {
    return { formQuestions: [], isLoading, isEmpty, isError, refetch, isFetching }
  }

  if (formQuestions.length > 0) {
    isEmpty = false
  }

  return { formQuestions, isLoading, isEmpty, isError, refetch, isFetching }
}

export const getFormQuestionsQueryKey = (formId: string) => ['forms', formId, 'questions']
