/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddRemoveApplePushNotificationDeviceTokenDto } from '../models/AddRemoveApplePushNotificationDeviceTokenDto';
import type { ApplePushNotificationDeviceTokenEntity } from '../models/ApplePushNotificationDeviceTokenEntity';
import type { NotificationEntity } from '../models/NotificationEntity';
import type { OrganizationUserNotificationPreferencesEntity } from '../models/OrganizationUserNotificationPreferencesEntity';
import type { UpdateNotificationsToReadDto } from '../models/UpdateNotificationsToReadDto';
import type { UpdateOrganizationUserNotificationPreferencesDto } from '../models/UpdateOrganizationUserNotificationPreferencesDto';
import type { UpdateUserCommunicationPreferencesDto } from '../models/UpdateUserCommunicationPreferencesDto';
import type { UpdateUserNotificationPreferencesDto } from '../models/UpdateUserNotificationPreferencesDto';
import type { UserCommunicationPreferencesEntity } from '../models/UserCommunicationPreferencesEntity';
import type { UserNotificationPreferencesEntity } from '../models/UserNotificationPreferencesEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotificationService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all notifications
     * @returns NotificationEntity The Notifications have been returned
     * @throws ApiError
     */
    public notificationControllerGetNotifications(): CancelablePromise<Array<NotificationEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/notification',
        });
    }
    /**
     * Update Notifications to read
     * @param requestBody
     * @returns NotificationEntity The Notifications have been updated and returned
     * @throws ApiError
     */
    public notificationControllerSetNotificationsToRead(
        requestBody: UpdateNotificationsToReadDto,
    ): CancelablePromise<Array<NotificationEntity>> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/notification/read',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the UserCommunicationPreferences
     * @returns UserCommunicationPreferencesEntity The UserCommunicationPreferences have been returned
     * @throws ApiError
     */
    public notificationControllerGetUserCommunicationPreferences(): CancelablePromise<UserCommunicationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/notification/userCommunicationPreferences',
        });
    }
    /**
     * Update the UserCommunicationPreferences
     * @param requestBody
     * @returns UserCommunicationPreferencesEntity UserCommunicationPreferences have been updated
     * @throws ApiError
     */
    public notificationControllerUpdateUserCommunicationPreferences(
        requestBody: UpdateUserCommunicationPreferencesDto,
    ): CancelablePromise<UserCommunicationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/notification/userCommunicationPreferences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Add an APN device token to the UserCommunicationPreferences
     * @param requestBody
     * @returns ApplePushNotificationDeviceTokenEntity ApplePushNotificationDeviceToken has been created
     * @throws ApiError
     */
    public notificationControllerAddApplePushNotificationDeviceToken(
        requestBody: AddRemoveApplePushNotificationDeviceTokenDto,
    ): CancelablePromise<ApplePushNotificationDeviceTokenEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/notification/userCommunicationPreferences/apnDeviceToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Remove an APN device token
     * @param requestBody
     * @returns ApplePushNotificationDeviceTokenEntity ApplePushNotificationDeviceToken has been removed
     * @throws ApiError
     */
    public notificationControllerDeleteApplePushNotificationDeviceToken(
        requestBody: AddRemoveApplePushNotificationDeviceTokenDto,
    ): CancelablePromise<ApplePushNotificationDeviceTokenEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/notification/userCommunicationPreferences/apnDeviceToken',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the UserNotificationPreferences
     * @returns UserNotificationPreferencesEntity The UserNotificationPreferences have been returned
     * @throws ApiError
     */
    public notificationControllerGetUserNotificationPreferences(): CancelablePromise<UserNotificationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/notification/userNotificationPreferences',
        });
    }
    /**
     * Update the userNotificationPreferences
     * @param requestBody
     * @returns UserNotificationPreferencesEntity userNotificationPreferences have been updated
     * @throws ApiError
     */
    public notificationControllerUpdateUserNotificationPreferences(
        requestBody: UpdateUserNotificationPreferencesDto,
    ): CancelablePromise<UserNotificationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/notification/userNotificationPreferences',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get the OrganizationUserNotificationPreferences
     * @param organizationId
     * @returns OrganizationUserNotificationPreferencesEntity The OrganizationUserNotificationPreferences have been returned
     * @throws ApiError
     */
    public notificationControllerGetOrganizationUserNotificationPreferences(
        organizationId: string,
    ): CancelablePromise<OrganizationUserNotificationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/notification/OrganizationUserNotificationPreferences',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Update the OrganizationUserNotificationPreferences for an Organization
     * @param organizationId
     * @param requestBody
     * @returns OrganizationUserNotificationPreferencesEntity OrganizationUserNotificationPreferences has been updated
     * @throws ApiError
     */
    public notificationControllerUpdateOrganizationUserNotificationPreferences(
        organizationId: string,
        requestBody: UpdateOrganizationUserNotificationPreferencesDto,
    ): CancelablePromise<OrganizationUserNotificationPreferencesEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/notification/OrganizationUserNotificationPreferences',
            query: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
