import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { PublicMeetingWithBookingInfoEntity } from '../../../qourses-api-client'
import { useEffect, useRef } from 'react'

export default function useGetCourseMeetingsPublic(courseId: string): {
  meetings: PublicMeetingWithBookingInfoEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: meetings,
    isLoading,
    isError,
    refetch,
  } = useQuery(GetCourseMeetingsPublicQueryKey(courseId), async () => {
    return qoursesApi.coursePublic.coursePublicControllerGetCourseMeetings(courseId)
  })

  const eventSourceRef = useRef(null) // Use useRef to persist the eventSource across rerenders

  useEffect(() => {
    // Only create a new EventSource if one does not already exist
    if (!eventSourceRef.current) {
      eventSourceRef.current = new EventSource(
        `${qoursesApi.request.config.BASE}/v1/public/course/${courseId}/meetings/changes`,
      )
      eventSourceRef.current.onmessage = () => {
        refetch()
      }
    }

    return () => {
      if (eventSourceRef.current) {
        eventSourceRef.current.close()
        eventSourceRef.current = null
      }
    }
  }, [courseId]) // `courseId` is a dependency here to ensure connection uses the current courseId

  let isEmpty = true

  if (meetings === undefined) {
    return { meetings: [], isLoading, isEmpty, isError }
  }

  if (meetings.length > 0) {
    isEmpty = false
  }

  return { meetings, isLoading, isEmpty, isError }
}

export const GetCourseMeetingsPublicQueryKey = (courseId: string) => [
  'public',
  'meetings',
  courseId,
]
