import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { type BookingCancellationRefundEligibilityDto } from '../../../qourses-api-client'

export default function useGetBookingRefundOptions(
  bookingId: string,
  overrideTOS?: boolean,
): {
  bookingCancellationOptions: BookingCancellationRefundEligibilityDto
  isLoading: boolean
  isError: boolean
  refetch: () => void
  isRefetching: boolean
} {
  const {
    data: bookingCancellationOptions,
    isLoading,
    isError,
    refetch,
    isRefetching,
  } = useQuery(['booking', bookingId, 'refundOptions'], async () => {
    return qoursesApi.payment.paymentControllerGetBookingCancellationRefundEligibility(
      bookingId,
      overrideTOS,
    )
  })

  return { bookingCancellationOptions, isLoading, isError, refetch, isRefetching }
}
