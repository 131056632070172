import { QoursesClient } from '../../qourses-api-client'

const apiUrl = import.meta.env.VITE_API_URL

export const qoursesApi = new QoursesClient({
  BASE: apiUrl,
  HEADERS: {
    'Content-Type': 'application/json',
  },
})

export function setToken(token: string) {
  qoursesApi.request.config.HEADERS = {
    ...qoursesApi.request.config.HEADERS,
    Authorization: 'Bearer ' + token,
  }
}

export function setActiveApiOrganization(organization: string) {
  qoursesApi.request.config.HEADERS = {
    ...qoursesApi.request.config.HEADERS,
    'x-organization-slug': organization,
  }
}
