/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ManualBookingDto } from './ManualBookingDto';
export type PostOrderWithBookingsDto = {
    /**
     * If the Order for a course should include payment, we need a courseId.
     */
    courseId?: string | null;
    /**
     * If the Order for a multipass product should include payment, we need a multipassProductId.
     */
    multipassProductId?: string | null;
    guestId?: string | null;
    customerId?: string | null;
    bookings: Array<ManualBookingDto>;
    exclusiveUntil: string;
    checkoutType: PostOrderWithBookingsDto.checkoutType;
};
export namespace PostOrderWithBookingsDto {
    export enum checkoutType {
        FREE = 'FREE',
        STRIPE = 'STRIPE',
        PAY_LATER = 'PAY_LATER',
    }
}

