/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicGuestEntity } from '../models/PublicGuestEntity';
import type { PublicPostGuestDto } from '../models/PublicPostGuestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomerPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Create a Guest
     * @param requestBody
     * @returns PublicGuestEntity The Guest has been created
     * @throws ApiError
     */
    public customerPublicControllerCreateGuest(
        requestBody: PublicPostGuestDto,
    ): CancelablePromise<PublicGuestEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/public/customer/guest',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
