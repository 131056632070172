/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { CourseGroupEntity } from '../models/CourseGroupEntity';
import type { TagEntity } from '../models/TagEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class TagPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get a Tag by id
     * @param tagId
     * @param organizationId
     * @returns TagEntity Tag have been returned
     * @throws ApiError
     */
    public tagPublicControllerGetTag(
        tagId: string,
        organizationId: string,
    ): CancelablePromise<TagEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/tag/{tagId}',
            path: {
                'tagId': tagId,
            },
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get tag all tags for the given organization
     * @param organizationId
     * @returns TagEntity Tag have been returned
     * @throws ApiError
     */
    public tagPublicControllerGetAllTags(
        organizationId: string,
    ): CancelablePromise<Array<TagEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/tag/all',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get Courses of Tag
     * @param tagId
     * @param organizationId
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public tagPublicControllerGetTagCourses(
        tagId: string,
        organizationId: string,
    ): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/tag/{tagId}/courses',
            path: {
                'tagId': tagId,
            },
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get Course Groups of Tag
     * @param tagId
     * @param organizationId
     * @returns CourseGroupEntity Course Groups have been returned
     * @throws ApiError
     */
    public tagPublicControllerGetTagCourseGroups(
        tagId: string,
        organizationId: string,
    ): CancelablePromise<Array<CourseGroupEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/tag/{tagId}/courseGroups',
            path: {
                'tagId': tagId,
            },
            query: {
                'organizationId': organizationId,
            },
        });
    }
}
