/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationEntity } from '../models/OrganizationEntity';
import type { PostActiveCustomerStatusDto } from '../models/PostActiveCustomerStatusDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OrganizationAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Organizations of the platform
     * @returns OrganizationEntity All Organizations have been returned
     * @throws ApiError
     */
    public organizationAdminControllerGetAllOrganizations(): CancelablePromise<Array<OrganizationEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/organization',
        });
    }
    /**
     * Change the active customer status of an Organization
     * @param organizationId
     * @param requestBody
     * @returns OrganizationEntity Active customer status of the Organization has been changed
     * @throws ApiError
     */
    public organizationAdminControllerSetActiveCustomerStatus(
        organizationId: string,
        requestBody: PostActiveCustomerStatusDto,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/organization/{organizationId}/activeCustomer',
            path: {
                'organizationId': organizationId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                404: `Organization has not been found`,
            },
        });
    }
}
