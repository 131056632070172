/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type FormQuestionEntity = {
    id: string;
    createdAt: string;
    updatedAt: string;
    bookingStatus: FormQuestionEntity.bookingStatus;
    organizationId: string | null;
    instructorId: string | null;
    formId: string;
    weight: number;
    question: string;
    questionType: FormQuestionEntity.questionType;
    required: boolean;
    selectOptions: Array<any[]>;
    archived: boolean;
};
export namespace FormQuestionEntity {
    export enum bookingStatus {
        DRAFT = 'DRAFT',
        RESERVED = 'RESERVED',
        CLAIMED = 'CLAIMED',
        FULFILLED = 'FULFILLED',
        REFUNDED = 'REFUNDED',
        CANCELLED = 'CANCELLED',
        CANCELLED_REFUNDED = 'CANCELLED_REFUNDED',
        WITHDRAWN = 'WITHDRAWN',
    }
    export enum questionType {
        DATE = 'DATE',
        SELECT_CHECKBOX = 'SELECT_CHECKBOX',
        SELECT_RADIO = 'SELECT_RADIO',
        TEXTAREA = 'TEXTAREA',
        TEXT = 'TEXT',
    }
}

