import React, { Fragment, useState } from 'react'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Dialog, Transition } from '@headlessui/react'
import { UserCircleIcon, XMarkIcon } from '@heroicons/react/24/solid'
import { motion } from 'framer-motion'
import {
  classNames,
  copyBookingLinkToClipboard,
  sendNotification,
  ToastVariant,
} from './utils.tsx'
import { useAuth0 } from '@auth0/auth0-react'
import useOrganization from './hooks/organization/useOrganization.tsx'
import Skeleton from 'react-loading-skeleton'
import { useTranslation } from 'react-i18next'
import isEmpty from 'lodash/isEmpty'
import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  BookUser,
  Building2,
  Calendar,
  ClipboardCopy,
  ClipboardEdit,
  ExternalLink,
  Handshake,
  Menu,
  Plus,
  ReceiptEuro,
  Satellite,
  Settings2,
  SquareStack,
  Ticket,
  User,
  UsersRound,
} from 'lucide-react'
import { Toaster } from '@/shadcn/components/ui/sonner.tsx'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/shadcn/components/ui/select.tsx'
import useIsPlatformAdmin from '@/hooks/platformAdmin/useIsPlatformAdmin.tsx'
import { pushModal } from '@/components/modals'
import useGetPaymentProfile from '@/hooks/onboarding/useGetPaymentProfile.tsx'

interface SidebarProps {
  children: React.ReactNode
}

export default function Sidebar(props: SidebarProps) {
  const brandPageUrl = import.meta.env.VITE_BRAND_PAGE_URL
  const { t: translate } = useTranslation()

  const { paymentProfile, isLoading } = useGetPaymentProfile()

  const userIsPlatformAdmin = useIsPlatformAdmin()

  const navigate = useNavigate()

  const showNotificationBubbleForSettings =
    !isLoading &&
    (paymentProfile?.onboardingRequiresUpdate || !paymentProfile?.onboardingCompleted)

  const navigation = [
    {
      name: translate('sidebar.navigation.courses'),
      icon: Calendar,
      href: '/courses',
    },
    {
      name: translate('sidebar.navigation.meetings'),
      icon: SquareStack,
      href: '/meetings',
    },
    {
      name: translate('sidebar.navigation.customers'),
      icon: BookUser,
      href: '/customers',
    },
    {
      name: translate('sidebar.navigation.orders'),
      icon: Handshake,
      href: '/orders',
    },
    {
      name: translate('sidebar.navigation.instructors'),
      icon: User,
      href: '/instructors',
    },
    {
      name: translate('sidebar.navigation.multipasses'),
      icon: Ticket,
      href: '/multipasses',
    },
    {
      name: translate('sidebar.navigation.settings'),
      icon: Settings2,
      href: '/settings',
      divider: true,
      showNotificationBubble: showNotificationBubbleForSettings,
    },
  ]

  const [sidebarOpen, setSidebarOpen] = useState(false)
  const {
    hasOrganization,
    allOrganizations,
    organization: activeOrganization,
    handleOrganizationChange,
    isFetchingOrganizations,
  } = useOrganization()
  const { user } = useAuth0()

  /* const {
    unreadNotifications,
    readNotifications,
    isLoading: isLoadingNotifications,
    isError: isErrorNotifications,
    isEmpty: isEmptyNotifications,
    refetch: refetchNotifications,
    unreadAmount,
  } = useGetNotification()

  const [notificationsOpen, setNotificationsOpen] = useState(false)

  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        delay: 0.2,
        staggerChildren: 0.2,
      },
    },
  }

  const item = {
    hidden: { opacity: 0, translateY: 40 },
    show: {
      opacity: 1,
      translateY: 0,
    },
  }*/

  return (
    <>
      <Toaster position="top-right" />
      <div>
        {/* Mobile sidebar */}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">{translate('sidebar.close')}</span>
                        <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto rounded-r-2xl bg-white px-6 pb-2">
                    <div className="mt-4 flex shrink-0 items-center">
                      <img
                        className="h-6 w-auto rounded-md"
                        src="/qourses_logo.webp"
                        alt="qourses"
                      />
                      <p className="ml-2 bg-[url(/ffflux.svg)] bg-cover bg-clip-text bg-bottom align-middle font-merriweather-sans text-sm leading-7 text-transparent">
                        Qourses
                      </p>
                    </div>
                    {isFetchingOrganizations && (
                      <li>
                        <div className="text-xs font-semibold leading-6 text-gray-400">
                          {translate('sidebar.your-teams')}
                        </div>
                        <ul className="-mx-2 mt-2 space-y-1">
                          <Skeleton className="h-8 w-full" />
                        </ul>
                      </li>
                    )}
                    {hasOrganization &&
                      !isEmpty(allOrganizations) &&
                      !isFetchingOrganizations && (
                        <div>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            {translate('sidebar.your-teams')}
                          </div>
                          <Select
                            value={activeOrganization.slug}
                            onValueChange={(slug) => {
                              if (slug === 'NEW_ORG') {
                                navigate('/onboarding?allow=true')
                              }

                              const selectedOrg = allOrganizations.find(
                                (org) => org.slug === slug,
                              )
                              handleOrganizationChange(selectedOrg)
                            }}
                          >
                            <SelectTrigger>
                              <SelectValue>
                                <div className="flex items-center gap-x-2">
                                  <span className="bezel flex h-6 w-6 items-center justify-center rounded-lg border bg-indigo-500 text-[0.625rem] text-white">
                                    {activeOrganization.name[0]}
                                  </span>
                                  <p className="font-semibold">
                                    {activeOrganization.name}
                                  </p>
                                </div>
                              </SelectValue>
                            </SelectTrigger>
                            <SelectContent>
                              {allOrganizations.map((org) => (
                                <SelectItem value={org.slug} key={org.slug}>
                                  {org.name}
                                </SelectItem>
                              ))}
                              <SelectItem
                                className="italic text-gray-600"
                                value={'NEW_ORG'}
                                key={'NEW_ORG'}
                              >
                                <div className="flex items-center">
                                  <Plus className="mr-1 size-4 shrink-0" />
                                  {translate('sidebar.new-team')}
                                </div>
                              </SelectItem>
                            </SelectContent>
                          </Select>
                        </div>
                      )}
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <NavLink
                                  to={item.href}
                                  // Automatically close the sidebar when a link is clicked
                                  onClick={() => {
                                    setTimeout(() => {
                                      setSidebarOpen(false)
                                    }, 400)
                                  }}
                                  className={({ isActive }) =>
                                    [
                                      'group relative gap-x-3 rounded-xl bg-white p-2 transition-all',
                                      'flex text-sm font-medium outline-sky-400 focus-visible:outline-2',
                                      isActive
                                        ? 'text-indigo-600'
                                        : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                    ].join(' ')
                                  }
                                  style={{
                                    WebkitTapHighlightColor: 'transparent',
                                  }}
                                >
                                  {({ isActive }) => (
                                    <>
                                      <span className="flex size-6 items-center justify-center rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10">
                                        <item.icon
                                          className={classNames(
                                            isActive
                                              ? 'text-indigo-600'
                                              : 'text-gray-400 group-hover:text-indigo-600',
                                            'h-4 w-4 shrink-0',
                                          )}
                                          aria-hidden="true"
                                        />
                                      </span>
                                      {isActive && (
                                        <motion.span
                                          layoutId="bubble-sidebar"
                                          className="absolute inset-0 z-10 bg-white/[0.040] mix-blend-difference"
                                          style={{ borderRadius: 7 }}
                                          transition={{
                                            type: 'spring',
                                            bounce: 0.2,
                                            duration: 0.6,
                                          }}
                                        />
                                      )}
                                      {item.name}
                                    </>
                                  )}
                                </NavLink>
                              </li>
                            ))}

                            {userIsPlatformAdmin && (
                              <>
                                <div className="py-2 pl-2">
                                  <div className="mt-4 text-xs text-muted-foreground">
                                    Platform Admin
                                  </div>
                                </div>
                                <li key="platform-orgas" className="relative">
                                  <NavLink
                                    to={'/admin/organizations'}
                                    className={({ isActive }) =>
                                      [
                                        'group relative flex gap-x-3 rounded-xl bg-white p-2 text-sm transition-all',
                                        'font-medium outline-sky-400 focus-visible:outline-2',
                                        isActive
                                          ? 'text-indigo-600'
                                          : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                                      ].join(' ')
                                    }
                                    style={{
                                      WebkitTapHighlightColor: 'transparent',
                                    }}
                                  >
                                    {({ isActive }) => (
                                      <>
                                        <span className="flex items-center justify-center rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10 lg:h-6 lg:w-6">
                                          <Building2
                                            className={classNames(
                                              isActive
                                                ? 'text-indigo-600'
                                                : 'text-gray-400 group-hover:text-indigo-600',
                                              'h-4 w-4 shrink-0',
                                            )}
                                            aria-hidden="true"
                                          />
                                        </span>
                                        {isActive && (
                                          <motion.span
                                            layoutId="bubble"
                                            className="absolute inset-0 z-10 bg-white/[.025] mix-blend-difference"
                                            style={{ borderRadius: 7 }}
                                            transition={{
                                              type: 'spring',
                                              bounce: 0.2,
                                              duration: 0.6,
                                            }}
                                          />
                                        )}
                                        Organizations
                                      </>
                                    )}
                                  </NavLink>
                                </li>
                              </>
                            )}
                          </ul>
                        </li>
                        <div className="mt-auto">
                          <li>
                            <ul role="list" className="-mx-2 mt-2 space-y-2">
                              {hasOrganization && (
                                <li
                                  key={translate(
                                    'sidebar.secondary-navigation.booking-page',
                                  )}
                                >
                                  <Link
                                    to={
                                      '/book?o=' +
                                      activeOrganization?.id +
                                      '&a=' +
                                      ['details', 'location', 'instructor']
                                    }
                                    target="_blank"
                                    className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                                  >
                                    <ExternalLink className="size-4 shrink-0"></ExternalLink>
                                    <span className="truncate">
                                      {translate(
                                        'sidebar.secondary-navigation.booking-page',
                                      )}
                                    </span>
                                  </Link>
                                </li>
                              )}
                              <li
                                key={translate(
                                  'sidebar.secondary-navigation.booking-page-copy',
                                )}
                                className="flex justify-between"
                              >
                                <div
                                  onClick={() => {
                                    const clipboard = copyBookingLinkToClipboard({
                                      organizationId: activeOrganization!.id,
                                    })
                                    sendNotification(
                                      translate('common.copy-to-clipboard'),
                                      clipboard.substring(0, 30) + '...',
                                      ToastVariant.Info,
                                    )
                                  }}
                                  className="group flex cursor-pointer items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                                >
                                  <ClipboardCopy className="size-4 shrink-0" />
                                  <span className="truncate">
                                    {translate(
                                      'sidebar.secondary-navigation.booking-page-copy',
                                    )}
                                  </span>
                                </div>
                                <div
                                  onClick={() => {
                                    pushModal('CreateBookingLinkModal')
                                  }}
                                  className="group flex cursor-pointer items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                                >
                                  <ClipboardEdit className="size-4 shrink-0" />
                                </div>
                              </li>
                              <li key={translate('sidebar.secondary-navigation.members')}>
                                <NavLink
                                  to={'members'}
                                  className={({ isActive }) =>
                                    [
                                      'group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600',
                                      isActive &&
                                        'bg-gray-50 text-indigo-600 ring-1 ring-indigo-200',
                                    ].join(' ')
                                  }
                                >
                                  <UsersRound className="size-4 shrink-0"></UsersRound>
                                  <span className="truncate">
                                    {translate('sidebar.secondary-navigation.members')}
                                  </span>
                                </NavLink>
                              </li>
                              <li key={translate('sidebar.secondary-navigation.help')}>
                                <Link
                                  to={'/me'}
                                  className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                                >
                                  <ReceiptEuro className="h-5 w-5 shrink-0" />
                                  <span className="truncate">
                                    {translate('sidebar.secondary-navigation.mePage')}
                                  </span>
                                </Link>
                              </li>
                            </ul>
                          </li>
                          <li className="-mx-5">
                            <NavLink
                              to="/user-settings"
                              className={({ isActive }) =>
                                [
                                  'group m-3 mb-2 flex items-center justify-evenly rounded-lg px-3 py-1 font-semibold leading-6 text-gray-900 ring-1 ring-inset ring-gray-200/80 transition-all hover:bg-indigo-50/30 hover:ring-indigo-400',
                                  isActive && 'bg-indigo-50/60 ring-indigo-400',
                                ].join(' ')
                              }
                            >
                              {user?.picture ? (
                                <img
                                  className="h-10 w-10 rounded-full bg-gray-50"
                                  src={user?.picture}
                                  alt="Profilbild"
                                />
                              ) : (
                                <UserCircleIcon
                                  className="h-10 w-10 text-gray-400"
                                  aria-hidden="true"
                                />
                              )}
                              <motion.span
                                aria-hidden="true"
                                className="truncate rounded-full px-2 py-2 text-sm font-semibold text-gray-800 transition-all"
                              >
                                {user?.given_name} {user?.family_name}
                              </motion.span>
                              <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                            </NavLink>
                          </li>
                          <li>
                            <div className="mb-2 flex items-center justify-evenly text-[10px] text-gray-400">
                              <div>
                                <a href={`${brandPageUrl}/privacy`}>
                                  {translate('sidebar.footer.privacy-policy')}
                                </a>
                              </div>
                              <div>
                                <a href={`${brandPageUrl}/tos`}>
                                  {translate('sidebar.footer.terms-of-use')}
                                </a>
                              </div>
                            </div>
                          </li>
                        </div>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="lg:w-62 relative hidden lg:fixed lg:inset-y-0 lg:flex lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6">
            <div className="mt-4 flex shrink-0 items-center">
              <img
                className="h-6 w-auto rounded-md"
                src="/qourses_logo.webp"
                alt="qourses"
              />
              <p className="ml-2 bg-[url(/ffflux.svg)] bg-cover bg-clip-text bg-bottom align-middle font-merriweather-sans text-sm leading-7 text-transparent">
                Qourses
              </p>
            </div>
            {isFetchingOrganizations && (
              <li>
                <div className="text-xs font-semibold leading-6 text-gray-400">
                  {translate('sidebar.your-teams')}
                </div>
                <ul className="-mx-2 mt-2 space-y-1">
                  <Skeleton className="h-8 w-full" />
                </ul>
              </li>
            )}
            {hasOrganization &&
              !isEmpty(allOrganizations) &&
              !isFetchingOrganizations && (
                <div>
                  <div className="text-xs font-semibold leading-6 text-gray-400">
                    {translate('sidebar.your-teams')}
                  </div>
                  <Select
                    value={activeOrganization.slug}
                    onValueChange={(slug) => {
                      if (slug === 'NEW_ORG') {
                        navigate('/onboarding?allow=true')
                      }

                      const selectedOrg = allOrganizations.find(
                        (org) => org.slug === slug,
                      )
                      handleOrganizationChange(selectedOrg)
                    }}
                  >
                    <SelectTrigger className="min-w-[240px] max-w-[240px]">
                      <SelectValue>
                        <div className="flex items-center gap-x-2">
                          <span className="bezel flex h-6 w-6 items-center justify-center rounded-lg border bg-indigo-500 text-[0.625rem] text-white">
                            {activeOrganization.name[0]}
                          </span>
                          <p className="truncate font-semibold">
                            {activeOrganization.name}
                          </p>
                        </div>
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      {allOrganizations.map((org) => (
                        <SelectItem value={org.slug} key={org.slug}>
                          {org.name}
                        </SelectItem>
                      ))}
                      <SelectItem
                        className="italic text-gray-600"
                        value={'NEW_ORG'}
                        key={'NEW_ORG'}
                      >
                        <div className="flex items-center">
                          <Plus className="mr-1 size-4 shrink-0" />
                          {translate('sidebar.new-team')}
                        </div>
                      </SelectItem>
                    </SelectContent>
                  </Select>
                </div>
              )}
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <>
                        {item.divider && (
                          <div className="relative py-2">
                            <div
                              className="absolute inset-0 flex items-center"
                              aria-hidden="true"
                            >
                              <div className="w-full border-t border-gray-200"></div>
                            </div>
                            <div className="relative flex justify-center"></div>
                          </div>
                        )}
                        <li key={item.name} className="relative">
                          <NavLink
                            to={item.href}
                            className={({ isActive }) =>
                              [
                                'group relative flex gap-x-3 rounded-xl bg-white p-2 text-sm transition-all',
                                'font-medium outline-sky-400 focus-visible:outline-2',
                                isActive
                                  ? 'text-indigo-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              ].join(' ')
                            }
                            style={{
                              WebkitTapHighlightColor: 'transparent',
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <span className="flex items-center justify-center rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10 lg:h-6 lg:w-6">
                                  <item.icon
                                    className={classNames(
                                      isActive
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-4 w-4 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                                {isActive && (
                                  <motion.span
                                    layoutId="bubble"
                                    className="absolute inset-0 z-10 bg-white/[.025] mix-blend-difference"
                                    style={{ borderRadius: 7 }}
                                    transition={{
                                      type: 'spring',
                                      bounce: 0.2,
                                      duration: 0.6,
                                    }}
                                  />
                                )}
                                {item.name}
                                {item.showNotificationBubble && (
                                  <motion.div
                                    layoutId="notification-bubble"
                                    className="mr-2 flex h-5 w-5 justify-end rounded-full bg-red-500 ring-4 ring-red-200"
                                    transition={{
                                      rotate: {
                                        repeat: Infinity,
                                        repeatDelay: 1.3,
                                        duration: 0.5,
                                      },
                                      translateX: {
                                        type: 'spring',
                                        bounce: 0.3,
                                      },
                                      opacity: {
                                        duration: 0.3,
                                      },
                                    }}
                                    animate={{
                                      opacity: 1,
                                      rotate: [0, -30, 30, 0, 15, 0],
                                    }}
                                  >
                                    <p className="mx-auto my-auto text-xs font-semibold text-white">
                                      1
                                    </p>
                                  </motion.div>
                                )}
                              </>
                            )}
                          </NavLink>
                        </li>
                      </>
                    ))}

                    {userIsPlatformAdmin && (
                      <>
                        <div className="py-2 pl-2">
                          <div className="mt-4 text-xs text-muted-foreground">
                            Platform Admin
                          </div>
                        </div>
                        <li key="platform-orgas" className="relative">
                          <NavLink
                            to={'/admin/organizations'}
                            className={({ isActive }) =>
                              [
                                'group relative flex gap-x-3 rounded-xl bg-white p-2 text-sm transition-all',
                                'font-medium outline-sky-400 focus-visible:outline-2',
                                isActive
                                  ? 'text-indigo-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              ].join(' ')
                            }
                            style={{
                              WebkitTapHighlightColor: 'transparent',
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <span className="flex items-center justify-center rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10 lg:h-6 lg:w-6">
                                  <Building2
                                    className={classNames(
                                      isActive
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-4 w-4 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                                {isActive && (
                                  <motion.span
                                    layoutId="bubble"
                                    className="absolute inset-0 z-10 bg-white/[.025] mix-blend-difference"
                                    style={{ borderRadius: 7 }}
                                    transition={{
                                      type: 'spring',
                                      bounce: 0.2,
                                      duration: 0.6,
                                    }}
                                  />
                                )}
                                Organizations
                              </>
                            )}
                          </NavLink>
                          <NavLink
                            to={'/admin/pulse'}
                            className={({ isActive }) =>
                              [
                                'group relative flex gap-x-3 rounded-xl bg-white p-2 text-sm transition-all',
                                'font-medium outline-sky-400 focus-visible:outline-2',
                                isActive
                                  ? 'text-indigo-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-indigo-600',
                              ].join(' ')
                            }
                            style={{
                              WebkitTapHighlightColor: 'transparent',
                            }}
                          >
                            {({ isActive }) => (
                              <>
                                <span className="flex items-center justify-center rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10 lg:h-6 lg:w-6">
                                  <Satellite
                                    className={classNames(
                                      isActive
                                        ? 'text-indigo-600'
                                        : 'text-gray-400 group-hover:text-indigo-600',
                                      'h-4 w-4 shrink-0',
                                    )}
                                    aria-hidden="true"
                                  />
                                </span>
                                {isActive && (
                                  <motion.span
                                    layoutId="bubble"
                                    className="absolute inset-0 z-10 bg-white/[.025] mix-blend-difference"
                                    style={{ borderRadius: 7 }}
                                    transition={{
                                      type: 'spring',
                                      bounce: 0.2,
                                      duration: 0.6,
                                    }}
                                  />
                                )}
                                Pulse Jobs
                              </>
                            )}
                          </NavLink>
                        </li>
                      </>
                    )}
                  </ul>
                </li>
                <div className="mt-auto">
                  <li>
                    <ul role="list" className="-mx-2 mt-2 space-y-2">
                      {hasOrganization && (
                        <li key={translate('sidebar.secondary-navigation.booking-page')}>
                          <Link
                            to={
                              '/book?o=' +
                              activeOrganization?.id +
                              '&a=' +
                              ['details', 'location', 'instructor']
                            }
                            target="_blank"
                            className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                          >
                            <ExternalLink className="size-4 shrink-0"></ExternalLink>
                            <span className="truncate">
                              {translate('sidebar.secondary-navigation.booking-page')}
                            </span>
                          </Link>
                        </li>
                      )}
                      <li
                        key={translate('sidebar.secondary-navigation.booking-page-copy')}
                        className="flex justify-between"
                      >
                        <div
                          onClick={() => {
                            const clipboard = copyBookingLinkToClipboard({
                              organizationId: activeOrganization!.id,
                            })
                            sendNotification(
                              translate('common.copy-to-clipboard'),
                              clipboard.substring(0, 30) + '...',
                              ToastVariant.Info,
                            )
                          }}
                          className="group flex cursor-pointer items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <ClipboardCopy className="size-4 shrink-0" />
                          <span className="truncate">
                            {translate('sidebar.secondary-navigation.booking-page-copy')}
                          </span>
                        </div>
                        <div
                          onClick={() => {
                            pushModal('CreateBookingLinkModal')
                          }}
                          className="group flex cursor-pointer items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <ClipboardEdit className="size-4 shrink-0" />
                        </div>
                      </li>
                      <li key={translate('sidebar.secondary-navigation.members')}>
                        <NavLink
                          to={'members'}
                          className={({ isActive }) =>
                            [
                              'group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600',
                              isActive &&
                                'bg-gray-50 text-indigo-600 ring-1 ring-indigo-200',
                            ].join(' ')
                          }
                        >
                          <UsersRound className="size-4 shrink-0"></UsersRound>
                          <span className="truncate">
                            {translate('sidebar.secondary-navigation.members')}
                          </span>
                        </NavLink>
                      </li>
                      <li key={translate('sidebar.secondary-navigation.help')}>
                        <Link
                          to={'/me'}
                          className="group flex items-center gap-x-3 rounded-md p-2 text-sm font-normal leading-6 text-gray-700 transition-all hover:bg-gray-50 hover:text-indigo-600"
                        >
                          <ReceiptEuro className="h-5 w-5 shrink-0" />
                          <span className="truncate">
                            {translate('sidebar.secondary-navigation.mePage')}
                          </span>
                        </Link>
                      </li>
                      {/*<li>
                        <div
                          onClick={async () => {
                            setNotificationsOpen(!notificationsOpen)
                            if (!notificationsOpen && unreadAmount > 0) {
                              const unreadIds = unreadNotifications.map((n) => n.id)
                              await qoursesApi.notification.notificationControllerSetNotificationsToRead(
                                {
                                  notificationIds: unreadIds,
                                },
                              )
                            }
                            if (notificationsOpen) {
                              refetchNotifications()
                            }
                          }}
                          className={classNames(
                            notificationsOpen
                              ? 'text-indigo-600 bg-gray-50 ring-1 ring-indigo-200'
                              : 'transition-all text-gray-400 group-hover:text-indigo-600',
                            'cursor-pointer overflow-visible justify-between ring-gray-200 transition-all flex items-center text-gray-700 hover:text-indigo-600 hover:bg-gray-50 group gap-x-3 rounded-md p-2 text-sm leading-6 font-normal',
                          )}
                        >
                          <div className="flex items-center gap-x-3">
                            {notificationsOpen ? (
                              <>
                                <MailOpen className="size-4 shrink-0" />
                              </>
                            ) : (
                              <>
                                <Mail className="size-4 shrink-0" />
                              </>
                            )}
                            <span className="truncate">
                              {translate('sidebar.notifications.button')}
                            </span>
                          </div>
                          <AnimatePresence>
                            {isLoadingNotifications && !isErrorNotifications ? (
                              <></>
                            ) : (
                              !isEmptyNotifications &&
                              unreadAmount > 0 && (
                                <motion.div
                                  layoutId="notification-bubble"
                                  className="flex bg-red-500 rounded-full w-5 h-5 mr-2 ring-red-200 ring-4"
                                  transition={{
                                    rotate: {
                                      repeat: Infinity,
                                      repeatDelay: 1.3,
                                      duration: 0.5,
                                    },
                                    translateX: {
                                      type: 'spring',
                                      bounce: 0.3,
                                    },
                                    opacity: {
                                      duration: 0.3,
                                    },
                                  }}
                                  animate={{
                                    translateX: notificationsOpen ? -100 : 0,
                                    opacity: notificationsOpen ? 0 : 1,
                                    rotate: [0, -30, 30, 0, 15, 0],
                                  }}
                                >
                                  <p className="my-auto mx-auto font-semibold text-xs text-white">
                                    {unreadAmount}
                                  </p>
                                </motion.div>
                              )
                            )}
                            {notificationsOpen && (
                              <motion.div
                                initial={{ opacity: 0, translateX: 30 }}
                                animate={{ opacity: 1, translateX: 0 }}
                                exit={{ opacity: 0, translateX: 30 }}
                                transition={{
                                  duration: 0.3,
                                  type: 'spring',
                                }}
                              >
                                <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                              </motion.div>
                            )}
                          </AnimatePresence>
                        </div>
                        <AnimatePresence>
                          {notificationsOpen && (
                            <motion.div
                              layoutId="notification-popover"
                              layout
                              initial={{ scale: 0.75, height: 0, width: 250 }}
                              animate={{
                                scale: 1,
                                // This basically calcs the height of the popover based on how many unread notifications we have
                                height:
                                  unreadAmount === 0
                                    ? 300
                                    : Math.min(unreadAmount * 100, 300),
                                width: 300,
                                transition: { duration: 0.6, type: 'spring' },
                              }}
                              exit={{
                                opacity: 0,
                                height: 0,
                                width: 300,
                                transition: { duration: 0.2 },
                              }}
                              className="cursor-default overflow-auto bg-white z-100 absolute border-2 border-gray-300 bottom-24 -right-[320px] origin-bottom-left rounded-lg shadow-lg"
                            >
                              <motion.div
                                variants={container}
                                initial="hidden"
                                animate="show"
                                exit={{ opacity: 0 }}
                              >
                                {unreadNotifications.length === 0 && (
                                  <div className="m-3">
                                    <EmptyState
                                      title={translate('sidebar.notifications.no-unread')}
                                    />
                                  </div>
                                )}
                                {unreadNotifications.map((notification) => (
                                  <motion.div
                                    key={notification.id}
                                    variants={item}
                                    exit={{ opacity: 0 }}
                                    className="my-3 mx-2 text-sm rounded-lg flex-row gap-x-4 items-center p-3 text-md font-medium bg-indigo-50/50 m-2 text-gray-800 hover:text-gray-800"
                                  >
                                    <p className="flex text-gray-600 mb-3 justify-between text-xs items-center">
                                      <div className="flex items-center gap-x-2">
                                        <div className="h-2 w-2 bg-indigo-500 ring-2 ring-indigo-300 rounded-full" />
                                        {DateTime.fromISO(
                                          notification.createdAt,
                                        ).toLocaleString(DateTime.DATETIME_MED)}
                                      </div>
                                    </p>
                                    <div className="flex flex-1">
                                      <p>{notification.body}</p>
                                    </div>
                                  </motion.div>
                                ))}

                                {readNotifications.length !== 0 && (
                                  <div className="relative py-2">
                                    <div
                                      className="absolute inset-0 flex items-center"
                                      aria-hidden="true"
                                    >
                                      <div className="w-full border-t border-gray-300" />
                                    </div>
                                    <div className="relative flex justify-center">
                                      <span className="bg-white px-3 text-sm font-semibold leading-6 text-gray-600">
                                        {translate('sidebar.notifications.read')}
                                      </span>
                                    </div>
                                  </div>
                                )}
                                {readNotifications.map((notification) => (
                                  <>
                                    <motion.div
                                      key={notification.id}
                                      variants={item}
                                      exit={{ opacity: 0 }}
                                      className="my-3 mx-2 text-sm rounded-lg flex-row gap-x-4 items-center p-3 text-md font-medium bg-gray-50 m-2 text-gray-500"
                                    >
                                      <p className="flex text-gray-400 mb-3 justify-between text-xs items-center">
                                        {DateTime.fromISO(
                                          notification.createdAt,
                                        ).toLocaleString(DateTime.DATETIME_MED)}
                                      </p>
                                      <div className="flex flex-1">
                                        <p>{notification.body}</p>
                                      </div>
                                    </motion.div>
                                  </>
                                ))}
                              </motion.div>
                            </motion.div>
                          )}
                        </AnimatePresence>
                      </li>*/}
                    </ul>
                  </li>
                  <li className="-mx-5">
                    <NavLink
                      to="/user-settings"
                      className={({ isActive }) =>
                        [
                          'group m-3 mb-2 flex items-center justify-evenly rounded-lg px-3 py-1 font-semibold leading-6 text-gray-900 ring-1 ring-inset ring-gray-200/80 transition-all hover:bg-indigo-50/30 hover:ring-indigo-400',
                          isActive && 'bg-indigo-50/60 ring-indigo-400',
                        ].join(' ')
                      }
                    >
                      {user?.picture ? (
                        <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src={user?.picture}
                          alt="Profilbild"
                        />
                      ) : (
                        <UserCircleIcon
                          className="h-10 w-10 text-gray-400"
                          aria-hidden="true"
                        />
                      )}
                      <motion.span
                        aria-hidden="true"
                        className="truncate rounded-full px-2 py-2 text-sm font-semibold text-gray-800 transition-all"
                      >
                        {user?.given_name} {user?.family_name}
                      </motion.span>
                      <ChevronRightIcon className="h-5 w-5 text-gray-400" />
                    </NavLink>
                  </li>
                  <li>
                    <div className="mb-2 flex items-center justify-evenly text-[10px] text-gray-400">
                      <div>
                        <a href={`${brandPageUrl}/privacy`}>
                          {translate('sidebar.footer.privacy-policy')}
                        </a>
                      </div>
                      <div>
                        <a href={`${brandPageUrl}/tos`}>
                          {translate('sidebar.footer.terms-of-use')}
                        </a>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </nav>
          </div>
        </div>

        <div className="sticky top-0 flex items-center justify-between gap-x-6 bg-white px-4 py-2 shadow-sm sm:px-6 lg:hidden">
          <button
            type="button"
            className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">{translate('sidebar.open')}</span>
            <Menu className="h-6 w-6" aria-hidden="true" />
          </button>
          <Link
            to="/user-settings"
            className="flex items-center gap-x-2 rounded-md py-1 text-sm font-semibold leading-6 text-gray-900 transition-all hover:bg-gray-50"
          >
            {user?.picture ? (
              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src={user.picture}
                alt="Profile picture"
              />
            ) : (
              <UserCircleIcon className="h-8 w-8 text-gray-300" aria-hidden="true" />
            )}
          </Link>
        </div>
        <main className="lg:pl-72">
          <div className="bg-white sm:py-4 sm:pr-4">
            <div className="min-h-dvh bg-gray-50/80 px-4 py-10 sm:rounded-xl sm:px-6 sm:ring-1 sm:ring-gray-100 lg:px-8">
              {props.children}
            </div>
          </div>
        </main>
      </div>
    </>
  )
}
