/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PatchFormQuestionDto = {
    weight: number;
    question: string;
    questionType: PatchFormQuestionDto.questionType;
    required: boolean;
    selectOptions: Array<string>;
};
export namespace PatchFormQuestionDto {
    export enum questionType {
        DATE = 'DATE',
        SELECT_CHECKBOX = 'SELECT_CHECKBOX',
        SELECT_RADIO = 'SELECT_RADIO',
        TEXTAREA = 'TEXTAREA',
        TEXT = 'TEXT',
    }
}

