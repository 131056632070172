import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { FormEntity } from '../../../qourses-api-client'

export default function useGetForm(formId: string): {
  form: FormEntity | undefined
  isLoading: boolean
  isError: boolean
  refetch: () => Promise<any>
} {
  const {
    data: form,
    isLoading,
    isError,
    refetch,
  } = useQuery([getFormQueryKey(formId)], async () => {
    return qoursesApi.survey.surveyControllerGetForm(formId, 'false')
  })

  return { form, isLoading, isError, refetch }
}

export const getFormQueryKey = (formId: string) => ['forms', formId]
