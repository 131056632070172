import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { MeetingEntity } from '../../../qourses-api-client'
import isEmpty from 'lodash/isEmpty'

export default function useGetCourseMeetings(courseId: string): {
  meetings: MeetingEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
  refetch: () => void
} {
  const {
    data: meetings,
    isLoading,
    refetch,
    isError,
  } = useQuery(getCourseMeetingsQueryKey(courseId), async () => {
    if (courseId === undefined) {
      return
    }
    return qoursesApi.course.courseControllerGetCourseMeetings(courseId)
  })

  const _isEmpty = isEmpty(meetings)

  if (meetings === undefined) {
    return { meetings: [], isLoading, isEmpty: _isEmpty, isError, refetch }
  }

  return { meetings, isLoading, isEmpty: _isEmpty, isError, refetch }
}

export const getCourseMeetingsQueryKey = (courseId: string) => [
  'courses',
  'meetings',
  courseId,
]
