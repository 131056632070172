/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicCourseGroupWithBookingInfoEntity } from '../models/PublicCourseGroupWithBookingInfoEntity';
import type { PublicMeetingEntity } from '../models/PublicMeetingEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CourseGroupPublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Course Groups including their Booking info
     * @param organizationId
     * @returns PublicCourseGroupWithBookingInfoEntity Course Groups have been returned
     * @throws ApiError
     */
    public courseGroupPublicControllerGetCourseGroups(
        organizationId: string,
    ): CancelablePromise<Array<PublicCourseGroupWithBookingInfoEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/courseGroup',
            query: {
                'organizationId': organizationId,
            },
        });
    }
    /**
     * Get a Course Group including its Booking info
     * @param courseGroupId
     * @returns PublicCourseGroupWithBookingInfoEntity Course Group has been returned
     * @throws ApiError
     */
    public courseGroupPublicControllerGetCourseGroup(
        courseGroupId: string,
    ): CancelablePromise<PublicCourseGroupWithBookingInfoEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/courseGroup/{courseGroupId}',
            path: {
                'courseGroupId': courseGroupId,
            },
        });
    }
    /**
     * Get Course Group Meetings
     * @param courseGroupId
     * @returns PublicMeetingEntity Course Group Meetings have been returned
     * @throws ApiError
     */
    public courseGroupPublicControllerGetCourseGroupMeetings(
        courseGroupId: string,
    ): CancelablePromise<Array<PublicMeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/courseGroup/{courseGroupId}/meetings',
            path: {
                'courseGroupId': courseGroupId,
            },
            errors: {
                404: `Course Group has not been found`,
            },
        });
    }
}
