/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { GuestEntity } from '../models/GuestEntity';
import type { PublicClaimGuestDto } from '../models/PublicClaimGuestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomerCustomerService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Claim a Guest
     * @param guestId
     * @param requestBody
     * @returns GuestEntity The Guest has been claimed
     * @throws ApiError
     */
    public customerCustomerControllerClaimGuest(
        guestId: string,
        requestBody: PublicClaimGuestDto,
    ): CancelablePromise<GuestEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/customer/customer/guest/{guestId}/claim',
            path: {
                'guestId': guestId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
}
