import { useTranslation } from 'react-i18next'
import useGetMeeting, { getMeetingQueryKey } from '@/hooks/meetings/useGetMeeting.tsx'
import {
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shadcn/components/ui/dialog.tsx'
import { TimePicker } from '@/shadcn/components/ui/time-picker-hours-and-minutes.tsx'
import { useEffect, useState } from 'react'
import { DateTime } from 'luxon'
import { Label } from '@/shadcn/components/ui/label.tsx'
import { Loader2 } from 'lucide-react'
import { Button } from '@/shadcn/components/ui/button.tsx'
import { minDelay, sendNotification, ToastVariant } from '@/utils.tsx'
import { qoursesApi } from '@/api/qourses.tsx'
import { popModal } from '@/components/modals/index.tsx'
import { useQueryClient } from '@tanstack/react-query'
import { getCourseMeetingsQueryKey } from '@/hooks/courses/useGetCourseMeetings.tsx'
import Dynamic from '@/components/modals/dynamic.tsx'

export function UpdateMeetingTimeModal({ meetingId }: { meetingId: string }) {
  const { t: translate } = useTranslation()

  const { meeting, isLoading, isError } = useGetMeeting(meetingId)

  const queryClient = useQueryClient()

  const [date, setDate] = useState<Date | undefined>()
  const [endDate, setEndDate] = useState<Date | undefined>()
  const [submitting, setSubmitting] = useState(false)
  const [changed, setChanged] = useState(false)

  useEffect(() => {
    if (meeting && !isLoading) {
      setDate(DateTime.fromISO(meeting.start).toJSDate())
      setEndDate(DateTime.fromISO(meeting.end).toJSDate())
    }
  }, [isLoading])

  // detect changes in date and endDate
  useEffect(() => {
    if (date && endDate) {
      setChanged(
        date.toISOString() !== meeting.start || endDate.toISOString() !== meeting.end,
      )
    }
  }, [date, endDate])

  async function handleUpdateMeetingTime() {
    try {
      setSubmitting(true)
      const duration = DateTime.fromJSDate(endDate)
        .diff(DateTime.fromJSDate(date))
        .as('minutes')

      await minDelay(
        qoursesApi.meeting.meetingControllerUpdateMeeting(meeting.id, {
          start: date.toISOString(),
          durationInMinutes: duration,
        }),
        1000,
      )

      await queryClient.invalidateQueries(getCourseMeetingsQueryKey(meeting.courseId))
      await queryClient.invalidateQueries(getMeetingQueryKey(meeting.id))
      sendNotification(
        translate('modals.updateMeetingTimeModal.notification.title'),
        translate('modals.updateMeetingTimeModal.notification.subtitle', {
          newStartTime: DateTime.fromJSDate(date).toLocaleString(DateTime.TIME_SIMPLE),
          newEndTime: DateTime.fromJSDate(endDate).toLocaleString(DateTime.TIME_SIMPLE),
        }),
        ToastVariant.Success,
      )
    } catch (error) {
      console.log(error)
    } finally {
      setSubmitting(false)
      popModal('UpdateMeetingTimeModal')
    }
  }

  if (isError) {
    return (
      <Dynamic.Content>
        <DialogHeader>
          <DialogTitle>
            {translate('modals.updateMeetingTimeModal.errorState.title')}
          </DialogTitle>
          <DialogDescription>
            {translate('modals.updateMeetingTimeModal.errorState.subtitle')}
          </DialogDescription>
        </DialogHeader>
      </Dynamic.Content>
    )
  }

  const startIsBeforeEnd = date && endDate && date < endDate

  return (
    <Dynamic.Content className="p-4 sm:p-8">
      <DialogHeader className="mb-2">
        <DialogTitle className="mt-6 sm:mt-2">
          {translate('modals.updateMeetingTimeModal.title')}
        </DialogTitle>
        <DialogDescription>
          {translate('modals.updateMeetingTimeModal.subtitle')}
        </DialogDescription>
      </DialogHeader>
      <div className="mt-2 space-y-3 rounded-lg bg-gray-50 p-3 ring-1 ring-gray-100">
        <Label>{translate('modals.updateMeetingTimeModal.timeLabel')}</Label>
        <TimePicker date={date} setDate={setDate} />
        <p className="text-xs text-gray-600">
          {translate('modals.updateMeetingTimeModal.timeLabelSubtitle')}
        </p>
      </div>
      <div className="mt-2 space-y-3 rounded-lg bg-gray-50 p-3 ring-1 ring-gray-100">
        <Label>{translate('modals.updateMeetingTimeModal.durationLabel')}</Label>
        <TimePicker date={endDate} setDate={setEndDate} />
        <p className="text-xs text-gray-600">
          {translate('modals.updateMeetingTimeModal.durationLabelSubtitle')}
        </p>
      </div>
      <div className="mt-4 flex flex-col justify-end">
        {!startIsBeforeEnd && (
          <p className="mb-2 text-xs text-gray-600">
            {translate('modals.updateMeetingTimeModal.startBeforeEnd')}
          </p>
        )}
        <Button
          variant="indigo"
          disabled={!startIsBeforeEnd || !changed}
          onClick={handleUpdateMeetingTime}
        >
          {submitting ? (
            <>
              <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              {translate('common.loading')}
            </>
          ) : (
            translate('modals.updateMeetingTimeModal.button')
          )}
        </Button>
      </div>
    </Dynamic.Content>
  )
}
