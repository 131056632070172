/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type RefundOptionDto = {
    type: RefundOptionDto.type;
    refundInMills: number | null;
    multipassId: string | null;
    refundedMultipassUsages: number;
};
export namespace RefundOptionDto {
    export enum type {
        NONE = 'NONE',
        REPAYMENT = 'REPAYMENT',
        CREDITS = 'CREDITS',
        MULTIPASS = 'MULTIPASS',
    }
}

