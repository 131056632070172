/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OrganizationEntity } from '../models/OrganizationEntity';
import type { PulseJobEntity } from '../models/PulseJobEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentAdminService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Update all onboarded Organizations with their respective company's legal name from Stripe. Dry Run will show the changes without saving them.
     * @param dryRun
     * @returns OrganizationEntity The updated Organizations have been returned
     * @throws ApiError
     */
    public paymentAdminControllerSetCompanyLegalNameForOnboardedOrganizations(
        dryRun: boolean,
    ): CancelablePromise<OrganizationEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/admin/payment/setCompanyLegalNameForOnboardedOrganizations',
            query: {
                'dryRun': dryRun,
            },
        });
    }
    /**
     * @returns PulseJobEntity The Pulse jobs for payment have been returned
     * @throws ApiError
     */
    public paymentAdminControllerGetPulsePaymentJobs(): CancelablePromise<Array<PulseJobEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/admin/payment/pulsePaymentJobs',
        });
    }
}
