/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export type PutLegalTermsDto = {
    type: PutLegalTermsDto.type;
    /**
     * The title of the legal text.
     */
    title: string;
    /**
     * The full legal text formatted with markdown.
     */
    text: string;
};
export namespace PutLegalTermsDto {
    export enum type {
        PRIVACY_POLICY = 'PRIVACY_POLICY',
        TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
        REVOCATION_POLICY = 'REVOCATION_POLICY',
    }
}

