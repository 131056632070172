/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { InstructorEntity } from '../models/InstructorEntity';
import type { MeetingEntity } from '../models/MeetingEntity';
import type { PostInstructorDto } from '../models/PostInstructorDto';
import type { UpdateInstructorDto } from '../models/UpdateInstructorDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class InstructorService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Instructors
     * @returns InstructorEntity Instructors have been returned
     * @throws ApiError
     */
    public instructorControllerGetInstructors(): CancelablePromise<Array<InstructorEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/instructor',
            errors: {
                404: `Instructor has not been found`,
            },
        });
    }
    /**
     * Create an Instructor
     * @param requestBody
     * @returns InstructorEntity Instructor has been created
     * @throws ApiError
     */
    public instructorControllerCreateInstructor(
        requestBody: PostInstructorDto,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/instructor',
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get an Instructor
     * @param instructorId
     * @returns InstructorEntity Instructor has been returned
     * @throws ApiError
     */
    public instructorControllerGetInstructor(
        instructorId: string,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/instructor/{instructorId}',
            path: {
                'instructorId': instructorId,
            },
            errors: {
                404: `Instructor has not been found`,
            },
        });
    }
    /**
     * Delete an Instructor
     * @param instructorId
     * @returns InstructorEntity Instructor has been deleted
     * @throws ApiError
     */
    public instructorControllerDeleteInstructor(
        instructorId: string,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'DELETE',
            url: '/v1/instructor/{instructorId}',
            path: {
                'instructorId': instructorId,
            },
            errors: {
                404: `Instructor has not been found`,
            },
        });
    }
    /**
     * Update an instructor
     * @param instructorId
     * @param requestBody
     * @returns InstructorEntity Instructor has been updated
     * @throws ApiError
     */
    public instructorControllerUpdateInstructor(
        instructorId: string,
        requestBody: UpdateInstructorDto,
    ): CancelablePromise<InstructorEntity> {
        return this.httpRequest.request({
            method: 'PATCH',
            url: '/v1/instructor/{instructorId}',
            path: {
                'instructorId': instructorId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get all courses an instructor is teaching
     * @param instructorId
     * @returns CourseEntity Instructor has been deleted
     * @throws ApiError
     */
    public instructorControllerGetInstructorCourses(
        instructorId: string,
    ): CancelablePromise<Array<CourseEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/instructor/{instructorId}/courses',
            path: {
                'instructorId': instructorId,
            },
        });
    }
    /**
     * Get all (non expired) meetings an instructor is teaching
     * @param instructorId
     * @returns MeetingEntity Instructor has been deleted
     * @throws ApiError
     */
    public instructorControllerGetInstructorMeetings(
        instructorId: string,
    ): CancelablePromise<Array<MeetingEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/instructor/{instructorId}/meetings',
            path: {
                'instructorId': instructorId,
            },
        });
    }
}
