/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { LegalTermsEntity } from '../models/LegalTermsEntity';
import type { PostLegalTermsDto } from '../models/PostLegalTermsDto';
import type { PostRevocationPolicyDto } from '../models/PostRevocationPolicyDto';
import type { PostTermsOfServiceDto } from '../models/PostTermsOfServiceDto';
import type { PutLegalTermsDto } from '../models/PutLegalTermsDto';
import type { PutRevocationPolicyDto } from '../models/PutRevocationPolicyDto';
import type { PutTermsOfServiceDto } from '../models/PutTermsOfServiceDto';
import type { RevocationPolicyEntity } from '../models/RevocationPolicyEntity';
import type { TermsOfServiceEntity } from '../models/TermsOfServiceEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class LegalService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Legal Terms
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalControllerGetAllLegalTerms(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<LegalTermsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/terms',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create Legal Terms
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms has been created
     * @throws ApiError
     */
    public legalControllerCreateLegalTerms(
        requestBody: PostLegalTermsDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<LegalTermsEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legal/terms',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Legal Terms
     * @param legalTermsId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalControllerGetLegalTermsById(
        legalTermsId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<LegalTermsEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/terms/{legalTermsId}',
            path: {
                'legalTermsId': legalTermsId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Replace Legal Terms (including attached Terms of Services & Revocation Policies)
     * @param legalTermsId
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms has been replaced
     * @throws ApiError
     */
    public legalControllerReplaceLegalTerms(
        legalTermsId: string,
        requestBody: PutLegalTermsDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<LegalTermsEntity> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legal/terms/{legalTermsId}',
            path: {
                'legalTermsId': legalTermsId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Terms of Services attached to some Legal Terms
     * @param legalTermsId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Services have been returned
     * @throws ApiError
     */
    public legalControllerGetTermsOfServicesOfLegalTermsById(
        legalTermsId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<TermsOfServiceEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/terms/{legalTermsId}/termsOfServices',
            path: {
                'legalTermsId': legalTermsId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Revocation Policies attached to some Legal Terms
     * @param legalTermsId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns RevocationPolicyEntity Revocation Policies have been returned
     * @throws ApiError
     */
    public legalControllerGetRevocationPoliciesOfLegalTermsById(
        legalTermsId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<RevocationPolicyEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/terms/{legalTermsId}/revocationPolicies',
            path: {
                'legalTermsId': legalTermsId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get all Terms of Services
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Services have been returned
     * @throws ApiError
     */
    public legalControllerGetAllTermsOfServices(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<TermsOfServiceEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/termsOfService',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create Terms of Service
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Services has been created
     * @throws ApiError
     */
    public legalControllerCreateTermsOfService(
        requestBody: PostTermsOfServiceDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TermsOfServiceEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legal/termsOfService',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Replace Terms of Service
     * @param termsOfServiceId
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Service have been replaced
     * @throws ApiError
     */
    public legalControllerReplaceTermsOfService(
        termsOfServiceId: string,
        requestBody: PutTermsOfServiceDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TermsOfServiceEntity> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legal/termsOfService/{termsOfServiceId}',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Terms of Service
     * @param termsOfServiceId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Service has been returned
     * @throws ApiError
     */
    public legalControllerGetTermsOfServiceById(
        termsOfServiceId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TermsOfServiceEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/termsOfService/{termsOfServiceId}',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Courses attached to Terms of Service
     * @param termsOfServiceId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public legalControllerGetTermsOfServiceCoursesById(
        termsOfServiceId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/termsOfService/{termsOfServiceId}/courses',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Terms of Service have not been found`,
            },
        });
    }
    /**
     * Get Legal Terms attached to Terms of Service
     * @param termsOfServiceId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalControllerGetLegalTermsOfTermsOfServiceById(
        termsOfServiceId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<LegalTermsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/termsOfService/{termsOfServiceId}/legalTerms',
            path: {
                'termsOfServiceId': termsOfServiceId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Terms of Service have not been found`,
            },
        });
    }
    /**
     * Get all Revocation Policies
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns RevocationPolicyEntity Revocation Policies have been returned
     * @throws ApiError
     */
    public legalControllerGetAllRevocationPolicies(
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<RevocationPolicyEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/revocationPolicy',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Create Revocation Policy
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns RevocationPolicyEntity Revocation Policy has been created
     * @throws ApiError
     */
    public legalControllerCreateRevocationPolicy(
        requestBody: PostRevocationPolicyDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<RevocationPolicyEntity> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/v1/legal/revocationPolicy',
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Replace Revocation Policy
     * @param revocationPolicyId
     * @param requestBody
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns TermsOfServiceEntity Terms of Service have been replaced
     * @throws ApiError
     */
    public legalControllerReplaceRevocationPolicy(
        revocationPolicyId: string,
        requestBody: PutRevocationPolicyDto,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<TermsOfServiceEntity> {
        return this.httpRequest.request({
            method: 'PUT',
            url: '/v1/legal/revocationPolicy/{revocationPolicyId}',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }
    /**
     * Get Revocation Policy
     * @param revocationPolicyId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns RevocationPolicyEntity Revocation Policy has been returned
     * @throws ApiError
     */
    public legalControllerGetRevocationPolicyById(
        revocationPolicyId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<RevocationPolicyEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/revocationPolicy/{revocationPolicyId}',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
        });
    }
    /**
     * Get Courses attached to Revocation Policy
     * @param revocationPolicyId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns CourseEntity Courses have been returned
     * @throws ApiError
     */
    public legalControllerGetRevocationPolicyCoursesById(
        revocationPolicyId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/revocationPolicy/{revocationPolicyId}/courses',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Revocation Policy has not been found`,
            },
        });
    }
    /**
     * Get Legal Terms attached to Revocation Policy
     * @param revocationPolicyId
     * @param xInstructorIndicator Indicator to scope the request to the instructor
     * @returns LegalTermsEntity Legal Terms have been returned
     * @throws ApiError
     */
    public legalControllerGetLegalTermsOfRevocationPolicyById(
        revocationPolicyId: string,
        xInstructorIndicator: string | null = 'true',
    ): CancelablePromise<Array<LegalTermsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/legal/revocationPolicy/{revocationPolicyId}/legalTerms',
            path: {
                'revocationPolicyId': revocationPolicyId,
            },
            headers: {
                'x-instructor-indicator': xInstructorIndicator,
            },
            errors: {
                404: `Terms of Service have not been found`,
            },
        });
    }
}
