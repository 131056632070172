import { useQuery } from '@tanstack/react-query'
import { qoursesApi } from '../../api/qourses.tsx'
import { PublicCourseGroupWithBookingInfoEntity } from '../../../qourses-api-client'

export default function useGetCourseGroupsPublic(courseId: string): {
  courseGroups: PublicCourseGroupWithBookingInfoEntity[]
  isLoading: boolean
  isEmpty: boolean
  isError: boolean
} {
  const {
    data: courseGroups,
    isLoading,
    isError,
  } = useQuery(GetCourseGroupsPublicQueryKey(courseId), async () => {
    return qoursesApi.coursePublic.coursePublicControllerGetCourseGroups(courseId)
  })

  let isEmpty = true

  if (courseGroups === undefined) {
    return { courseGroups: [], isLoading, isEmpty, isError }
  }

  if (courseGroups.length > 0) {
    isEmpty = false
  }

  return { courseGroups, isLoading, isEmpty, isError }
}

export const GetCourseGroupsPublicQueryKey = (courseId: string) => [
  'public',
  'courseGroups',
  courseId,
]
