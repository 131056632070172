/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CourseEntity } from '../models/CourseEntity';
import type { PublicCourseGroupWithBookingInfoEntity } from '../models/PublicCourseGroupWithBookingInfoEntity';
import type { PublicCourseWithPriceDetailsEntity } from '../models/PublicCourseWithPriceDetailsEntity';
import type { PublicInstructorEntity } from '../models/PublicInstructorEntity';
import type { PublicMeetingWithBookingInfoEntity } from '../models/PublicMeetingWithBookingInfoEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CoursePublicService {
    constructor(public readonly httpRequest: BaseHttpRequest) {}
    /**
     * Get all Courses
     * @param organizationId
     * @param tagId
     * @returns PublicCourseWithPriceDetailsEntity Courses have been returned
     * @throws ApiError
     */
    public coursePublicControllerGetCourses(
        organizationId: string,
        tagId: string,
    ): CancelablePromise<Array<PublicCourseWithPriceDetailsEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course',
            query: {
                'organizationId': organizationId,
                'tagId': tagId,
            },
        });
    }
    /**
     * Get a single course by id
     * @param courseId
     * @returns CourseEntity The course has been returned
     * @throws ApiError
     */
    public coursePublicControllerGetCourse(
        courseId: string,
    ): CancelablePromise<CourseEntity> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course/{courseId}',
            path: {
                'courseId': courseId,
            },
        });
    }
    /**
     * Get all ungrouped Meetings of a Course including their Booking info
     * @param courseId
     * @param orderId
     * @returns PublicMeetingWithBookingInfoEntity Course Meetings have been returned
     * @throws ApiError
     */
    public coursePublicControllerGetCourseMeetings(
        courseId: string,
        orderId?: string,
    ): CancelablePromise<Array<PublicMeetingWithBookingInfoEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course/{courseId}/meetings',
            path: {
                'courseId': courseId,
            },
            query: {
                'orderId': orderId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get all Course Groups of a Course including their Booking info
     * @param courseId
     * @returns PublicCourseGroupWithBookingInfoEntity Course Groups have been returned
     * @throws ApiError
     */
    public coursePublicControllerGetCourseGroups(
        courseId: string,
    ): CancelablePromise<Array<PublicCourseGroupWithBookingInfoEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course/{courseId}/courseGroups',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get the instructors of a course
     * @param courseId
     * @returns PublicInstructorEntity Course Instructors has been returned
     * @throws ApiError
     */
    public coursePublicControllerGetCourseInstructor(
        courseId: string,
    ): CancelablePromise<Array<PublicInstructorEntity>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course/{courseId}/instructor',
            path: {
                'courseId': courseId,
            },
            errors: {
                404: `Course has not been found`,
            },
        });
    }
    /**
     * Get live server-sent-events (SSE) about the Meetings of a Course including their Booking info
     * @param courseId
     * @returns any
     * @throws ApiError
     */
    public coursePublicControllerGetCourseMeetingEvents(
        courseId: string,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/v1/public/course/{courseId}/meetings/changes',
            path: {
                'courseId': courseId,
            },
        });
    }
}
