import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { AnimatePresence, motion } from 'framer-motion'
import { AnimatedCheckIcon } from '../../components/AnimatedCheckIcon.tsx'
import { Badge } from '@/shadcn/components/ui/badge.tsx'
import { AlertTriangle } from 'lucide-react'

export function Title(props: {
  title: string
  subtitle: string
  icon?: ReactNode
  complete?: boolean
  optional?: boolean
  hideCompletion?: boolean
}) {
  const { t: translate } = useTranslation()

  return (
    <div>
      <div className="flex items-center">
        {props.icon && (
          <div className="mr-2 h-6 w-6 shrink-0 rounded-md bg-gradient-to-b from-white/75 to-violet-100/75 p-1 text-indigo-600 shadow-sm shadow-violet-800/10 ring-1 ring-violet-800/10">
            {props.icon}
          </div>
        )}
        <h2 className="my-auto truncate text-base font-semibold leading-7 text-gray-900">
          {props.title}
        </h2>
        <div className="ml-2 rounded-lg bg-green-100"></div>
        {!props.hideCompletion && (
          <AnimatePresence mode="wait">
            {props.complete && !props.hideCompletion ? (
              <motion.span
                key={'complete' + props.title}
                initial={{ opacity: 0, translateX: 20 }}
                animate={{ opacity: 1, translateX: 0 }}
                exit={{
                  opacity: 0,
                  translateX: 20,
                }}
              >
                <Badge className="cursor-default bg-green-50 text-green-700 ring-1 ring-green-500 transition-all hover:bg-green-50 hover:ring-green-700">
                  {translate('pages.create-course.complete')}
                  <AnimatedCheckIcon className="ml-1 h-4 w-4 text-green-500" />
                </Badge>
              </motion.span>
            ) : (
              <>
                {props.optional ? (
                  <motion.span
                    key={'optional' + props.title}
                    initial={{ opacity: 0, translateX: 20 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: 20 }}
                  >
                    <Badge variant="outline" className="bg-white">
                      {translate('pages.create-course.optional')}
                    </Badge>
                  </motion.span>
                ) : (
                  <motion.span
                    key={'incomplete' + props.title}
                    initial={{ opacity: 0, translateX: 20 }}
                    animate={{ opacity: 1, translateX: 0 }}
                    exit={{ opacity: 0, translateX: 20 }}
                  >
                    <Badge className="cursor-default bg-yellow-50 text-yellow-800 ring-1 ring-yellow-500 transition-all hover:bg-yellow-50 hover:ring-yellow-700">
                      {translate('pages.create-course.incomplete')}
                      <AlertTriangle className="ml-1 h-4 w-4" />
                    </Badge>
                  </motion.span>
                )}
              </>
            )}
          </AnimatePresence>
        )}
      </div>
      <p className="mt-1 text-sm leading-6 text-gray-600">{props.subtitle}</p>
    </div>
  )
}
